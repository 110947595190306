import { useTranslation } from 'react-i18next';

import TitleQuotation from './../components/title-quotation';
import DownloadQuotation from './../components/download-quotation';
import AcceptQuotationForm from './../components/Forms/accept-quotation-form';
import Hr from '../../../components/Style/hr';

export default function FirstStepAcceptQuotation({ quotation, returnedData }) {
    const { t } = useTranslation();

    const handleReturnedData = (data) => {
        returnedData(data);
    };

    return (
        <>
            <TitleQuotation title={t('pageQuotation.step1.welcomeMessage')} description={t('pageQuotation.step1.description')} step="1" />
            <Hr />
            <DownloadQuotation quotation={quotation} />
            <Hr />
            <AcceptQuotationForm quotation={quotation} returnedData={handleReturnedData} />
        </>
    );
}