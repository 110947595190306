import { useTranslation } from 'react-i18next';

export default function ExplanationStep({ title, description, optionnal = false }) {
    const { t } = useTranslation();

    return (
        <div className="w-full md:w-64 flex flex-col gap-5">
            <div className='flex items-center'>
                <h2 className='font-bold text-lg text-secondary'>
                    {title}
                </h2>
                {optionnal && (
                    <span className="text-main text-xs ml-2">
                        {t("formInputs.optional")}
                    </span>
                )}
            </div>
            <p className='text-sm text-description opacity-60'>
                {description}
            </p>
        </div>
    )
};