import React from 'react';

export default function DescriptionSplit({ description, theme = "dark", text = "justify", opacity = "60", className = "" }) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(description, 'text/html');
    const descriptionSplitted = Array.from(doc.body.childNodes).map((node, index) => (
        <React.Fragment key={index}>
            {node.textContent}
            <br />
        </React.Fragment>
    ));

    return (
        <div className={`text-sm md:text-base ${theme === "dark" ? "text-description" : "text-white"} ${text === "justify" ? "text-justify" : "text-center"} opacity-${opacity} ${className} whitespace-pre-wrap`}>
            <div>{descriptionSplitted}</div>
        </div>
    );
}