import { useTranslation } from 'react-i18next';
import FlagLanguage from './flag-language';

const languages = {
    fr: { nativeName: 'Français', imgName: 'fr' },
    en: { nativeName: 'English', imgName: 'en' },
    // de: { nativeName: 'Deutsch', imgName: 'de' }
};

export default function I18nComponentChooseLanguage() {
    const { i18n } = useTranslation();

    return (
        <div>
            {Object.keys(languages).map((language) => (
                <button key={language} style={{ fontWeight: i18n.resolvedLanguage === language ? 'bold' : 'normal' }}
                    type="submit" onClick={() => i18n.changeLanguage(language)}>
                    <FlagLanguage language={languages[language].imgName} />
                </button>
            ))}
        </div>
    )
};