import React, { useMemo } from 'react';
import H3 from '../Titles/h3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faClock, faMoneyBillTransfer, faPhone } from '@fortawesome/free-solid-svg-icons';

export default function PaiementCard({ name, title, description, iconKey, disabled = false, onClick, selected }) {
    const isDisabled = disabled === "false" ? false : Boolean(disabled);

    // Use useMemo for performance optimization
    const iconComponent = useMemo(() => {
        const iconImports = { faCreditCard, faClock, faMoneyBillTransfer, faPhone };
        return iconImports[iconKey];
    }, [iconKey]);

    const handleClick = () => {
        if (onClick && !isDisabled) {
            onClick(name);
        }
    };

    const commonClasses = "bg-white border border-borderColor p-4 rounded-md flex cursor-pointer hover:border-main hover:text-main transition-all duration-100";
    const selectedClasses = "border-2 border-main text-main";
    const disabledClasses = isDisabled ? "cursor-not-allowed opacity-50" : "";

    const className = `${commonClasses} ${name === selected ? selectedClasses : ''} ${disabledClasses}`;

    return (
        <div onClick={handleClick} className={className}>
            {iconComponent && <FontAwesomeIcon icon={iconComponent} className='text-xl mt-2 mr-4' />}

            <div>
                <H3 title={title} fontWeight='normal' />
                <p className='text-xs md:text-sm text-description opacity-60'>{description}</p>
            </div>
        </div>
    );
}
