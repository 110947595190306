import React from 'react';
import FullWidthImage from '../Images/image-full-width';

export default function BackgroundImage({ image, alt, customHeight = "100vh", displayGradient = true }) {
    return (
        <div className="relative w-full h-auto">
            <FullWidthImage url={image} alt={alt} customHeight={customHeight} />
            <div className={`absolute inset-0 ${displayGradient ? "bg-gradient-to-b from-transparent to-white" : ""}`} />
        </div>
    );
}