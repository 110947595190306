import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import imageHeaderBanner from "./../../assets/images/global/imageHeaderBanner.png";

// import Button from "../../components/Buttons/button";
import BackgroundImage from "../../components/Banner/background-header";
import H1 from "../../components/Titles/h1";
import { Helmet } from "react-helmet";
import DescriptionSplit from "../../components/Description/description-split";
import H3 from "../../components/Titles/h3";
import FullWidthImage from "../../components/Images/image-full-width";
import ImageBarbaraMarc from "../../assets/images/history/Barbara&Marc.jpg";
import H2 from "../../components/Titles/h2";

export default function History() {
    const { t } = useTranslation();
    const [images, setImages] = useState([]);
    const houseNames = t('history.names.data', { returnObjects: true });
    const imagesLoaded = useRef(false);

    useEffect(() => {
        if (!imagesLoaded.current) {
            const loadImages = async () => {
                const loadedImages = await Promise.all(
                    houseNames.map(async (name) => {
                        try {
                            const image = await import(`./../../assets/images/history/${name.img}.png`);
                            return image.default;
                        } catch (error) {
                            console.error('Error loading image:', error);
                            return null;
                        }
                    })
                );
                setImages(loadedImages.filter((img) => img !== null));
                imagesLoaded.current = true;
            };

            loadImages();
        }
    }, [houseNames]);

    return (
        <div>
            <Helmet>
                <title>{t('history.page')}</title>
                <meta name="description" content={t('history.description')} />
                <meta name="keywords" content={t('history.keywords')} />
            </Helmet>

            <div className="absolute top-0 w-full">
                <BackgroundImage image={imageHeaderBanner} alt={t('reservation.altBackground')} />
            </div>

            <div className="absolute left-1/2 transform -translate-x-1/2 top-1/2 w-4/5 md:w-3/5 text-center">
                <H1 title={t('history.title')} />

                <div className="flex items-center justify-center mt-12">
                    <H3 title={t('history.subtitle')} className="colored-underline text-black text-2xl leading-relaxed" />
                </div>
            </div>

            <div className="relative mt-[100vh] w-10/12 m-auto flex flex-col md:flex-row gap-10 md:gap-20 pb-12">
                <div className="w-full md:w-1/2 lg:w-7/12">
                    <DescriptionSplit description={t('history.paragraph')} />
                </div>
                <div className="w-full md:w-1/2 lg:w-5/12 p-4 bg-white rounded-xl h-fit m-auto shadow">
                    <FullWidthImage url={ImageBarbaraMarc} alt={t('history.altImage')} className="rounded-xl" />
                </div>
            </div>

            <div className="mt-12 mb-24 text-center">
                <H2 title={t('history.names.title')} className="mb-6" />
                <DescriptionSplit description={t('history.names.description')} text="center" />

                <div className="mt-12 w-11/12 md:w-10/12 mx-auto flex flex-col md:flex-row justify-between gap-12 md:gap-16 text-left">
                    {houseNames.map((item, index) => (
                        <div key={index} className="mt-6 w-full md:w-1/3">
                            <FullWidthImage url={images[index]} divClassName={"relative"} customHeight={"auto"} />
                            <H3 title={item.title} className="my-4 whitespace-pre-wrap" />
                            <DescriptionSplit description={item.description} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}