import React, { useEffect, useState } from 'react';

import Input from '../Inputs/input';
import DateFieldInput from '../Inputs/date-field';
import Api from '../../services/api.service';

export default function EventInfoForm({ eventData, errors, updateEventInfoFormData, fullWidth = false }) {
    useEffect(() => {
        const fetchData = async () => {
            try {
                const blockedDates = Api.getBlockedDatesStored();
                setBlockedDates(blockedDates);
            } catch (error) {
                console.error("An error occurred:", error);
            }
        };

        fetchData();
    }, []);

    const [blockedDates, setBlockedDates] = useState([]);

    const tenYearsLater = new Date(new Date().setFullYear(new Date().getFullYear() + 10));

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateEventInfoFormData({ ...eventData, [name]: value });
    };

    const handleDateChange = (dates) => {
        console.log(eventData);

        updateEventInfoFormData({ ...eventData, stayDates: dates });
    };

    return (
        <div className={`grid gap-6 md:gap-8 ${fullWidth ? 'grid-cols-1' : 'grid-cols-1 md:grid-cols-2'}`}>
            <Input label="companyName" name="companyName" value={eventData.companyName} error={errors.companyName} onChange={handleInputChange} />
            <Input label="event" name="event" value={eventData.event} error={errors.event} onChange={handleInputChange} />
            <Input label="nbPersons" name="nbPersons" value={eventData.nbPersons} error={errors.nbPersons} onChange={handleInputChange} type="number" />
            <DateFieldInput
                label='stayDates'
                value={eventData.stayDates}
                minDate={new Date()}
                maxDate={tenYearsLater}
                onChange={handleDateChange}
                displayLabel={true}
                singleDate={false}
                blockedDates={blockedDates}
            />
        </div>
    );
}