import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import GuestInfoFormQuotation from './Forms/guest-info-form';

import Hr from '../../../components/Style/hr';

// import ButtonSecondary from '../../../components/Buttons/button-secondary';
import Button from '../../../components/Buttons/button';
import ExplanationStep from './explanation-step';
import Validator from '../../../services/validator.service';
import Helper from '../../../services/helper.service';
import Api from '../../../services/api.service';


export default function ListOfGuestsQuotation({ quotation, returnedData }) {
    const { t } = useTranslation();

    const [customer, setCustomerData] = useState(quotation.customer);
    const [errorsCustomer, setErrorsCustomer] = useState([{}]);

    const [adultsGuests, setAdultsGuests] = useState(mergeGuestsArray("adult"));
    const [errorsAdults, setErrorsAdults] = useState(createArrayOfErrors(quotation.mainInfos.numberOfGuests.adults - 1));

    const [childsGuests, setChildsGuests] = useState(mergeGuestsArray("child"));
    const [errorsChilds, setErrorsChilds] = useState(createArrayOfErrors(quotation.mainInfos.numberOfGuests.childs));

    const updateCustomerData = (newData) => {
        setCustomerData(newData);
    };

    const updateGuestData = (newData) => {
        const data = newData.type === "adult" ? adultsGuests : childsGuests;
        const updatedDataGuests = [...data];

        // Assuming newData has an "index" property indicating which guest to update.
        const index = newData.index;

        // Make sure the index is valid.
        if (index >= 0 && index < updatedDataGuests.length) {
            updatedDataGuests[index] = {
                ...updatedDataGuests[index],
                ...newData
            };

            // Update the state with the modified adultsGuests array.
            newData.type === "adult" ? setAdultsGuests(updatedDataGuests) : setChildsGuests(updatedDataGuests);
        };
    };

    function mergeGuestsArray(type) {
        let existingGuests = quotation.guests.filter(guest => guest.type === type);

        // Remove the first adult if the type is 'adult'
        if (type === "adult" && existingGuests.length) {
            existingGuests.splice(0, 1);
        }

        const numberOfGuests = { // Remove the original customer
            adults: quotation.mainInfos.numberOfGuests.adults - 1,
            childs: quotation.mainInfos.numberOfGuests.childs
        }

        while (existingGuests.length < numberOfGuests[type + "s"]) {
            existingGuests.push({
                firstname: "",
                lastname: "",
                email: "",
                birthdate: undefined,
                type: type
            });
        }

        return existingGuests;
    }

    function createArrayOfErrors(numberOfGuests) {
        const emptyObjectsArray = [];

        for (let i = 0; i < numberOfGuests; i++) {
            emptyObjectsArray.push({});
        }

        return emptyObjectsArray;
    }

    async function handleButtonActionClick(action) {
        if (action === "back") {
            returnedData('step1');
            // TODO ?????

        } else if (action === "validateGuests") {
            const tmpErrorsCustomer = Validator.validateGuests([customer], "customer", t);
            const tmpErrorsAdults = Validator.validateGuests(adultsGuests, "adult", t);
            const tmpErrorsChilds = Validator.validateGuests(childsGuests, "child", t);

            setErrorsCustomer(tmpErrorsCustomer);
            setErrorsAdults(tmpErrorsAdults);
            setErrorsChilds(tmpErrorsChilds);

            // Check if there are any errors
            const isCustomerInfoValid = Object.keys(tmpErrorsCustomer).length === 0;
            const isAdultsInfoValid = Object.keys(tmpErrorsAdults).length === 0;
            const isChildsInfoValid = Object.keys(tmpErrorsChilds).length === 0;

            if (isCustomerInfoValid && isAdultsInfoValid && isChildsInfoValid) {
                quotation = Helper.setGuestDataToQuotation(quotation, customer, adultsGuests, childsGuests);
                quotation.mainInfos.status = "paiement";

                const response = await Api.saveQuotation(quotation);
                Helper.handleApiResponse(response, t('responses.guestsValidated'), t('responses.quotationError'));

                returnedData("step3");

            } else {
                console.log(tmpErrorsCustomer);
                console.log(tmpErrorsAdults);
                console.log(tmpErrorsChilds);
            }
        }
    }

    return (
        <div className='px-4 md:px-0'>
            <div className="flex flex-col gap-5 md:flex-row md:gap-40">
                <div className="w-full md:w-64 flex flex-col gap-5">
                    <ExplanationStep
                        title={t('pageQuotation.step2.customer')}
                        description={t('pageQuotation.step2.customerDescription')} />
                </div>

                <div className="flex flex-col w-full">
                    <GuestInfoFormQuotation guest={customer} errors={errorsCustomer[0]} updateGuestData={updateCustomerData} />
                </div>
            </div>

            <Hr />

            {adultsGuests.length > 0 && (
                <>
                    <div className="flex flex-col gap-5 md:flex-row md:gap-40">
                        <div className="w-full md:w-64 flex flex-col gap-5">
                            <ExplanationStep
                                title={t('pageQuotation.step2.adults')}
                                description={t('pageQuotation.step2.adultsDescription')}
                                optionnal={true} />
                        </div>

                        <div className="flex flex-col w-full">
                            {adultsGuests.map((guest, index) => (
                                <div key={"adult" + index}>
                                    <GuestInfoFormQuotation guest={guest} index={index} errors={errorsAdults[index]} updateGuestData={updateGuestData} />
                                    {index < adultsGuests.length - 1 && <Hr />}
                                </div>
                            ))}
                        </div>
                    </div>

                    <Hr />
                </>
            )}

            {childsGuests.length > 0 && (
                <>
                    <div className="flex flex-col gap-5 md:flex-row md:gap-40">
                        <div className="flex flex-col gap-5" style={{ flex: "0 0 256px" }}>
                            <ExplanationStep
                                title={t('pageQuotation.step2.childs')}
                                description={t('pageQuotation.step2.childsDescription')}
                                optionnal={true} />
                        </div>

                        <div className="flex flex-col w-full">
                            {childsGuests.map((guest, index) => (
                                <div key={"child" + index}>
                                    <GuestInfoFormQuotation guest={guest} index={index} errors={errorsChilds[index]} updateGuestData={updateGuestData} />
                                    {index < childsGuests.length - 1 && <Hr />}
                                </div>
                            ))}
                        </div>
                    </div>

                    <Hr />
                </>
            )}

            <div className="flex flex-col gap-5 md:flex-row md:justify-end mb-10">
                {/* <ButtonSecondary text={t('inputs.back')} action="back" onClick={handleButtonActionClick} /> */}
                <Button text={t('inputs.next')} action="validateGuests" onClick={handleButtonActionClick} />
            </div>
        </div>
    )
};