import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';

import imageVignes from "./../../assets/images/home/vignes.jpg";
import BannerHome from "./../../assets/images/home/banner-home.png"
import ImageServiceBell from "./../../assets/images/home/service-bell.png"

import { map } from "../../utils/imports/import-images.jsx";
import { Helmet } from 'react-helmet';
import iconBed from './../../assets/icons/home/bed.svg';
import iconPool from './../../assets/icons/home/pool.svg';
import iconPremium from './../../assets/icons/home/premium.svg';
import iconTree from './../../assets/icons/home/tree.svg';
import iconMultisport from './../../assets/icons/home/multisport.svg';
import iconSauna from './../../assets/icons/home/sauna.svg';

import imageData from '../../utils/imports/import-images.json';
import HeaderBanner from './components/sections/header-banner.jsx';
import SectionPhotoGallery from './components/sections/photo-gallery';
import SectionDomainOverview from './components/sections/domain-overview.jsx';
import SectionMainHouseAnnex from './components/sections/domain-and-annex-presentation.jsx';
import SectionGardenPresentation from './components/sections/garden-presentation.jsx';
import SectionProfessionalConcierge from './components/sections/professional-concierge.jsx';
import SectionMap from './components/sections/map.jsx';
import SectionServices from './components/sections/banner-services.jsx';
import SectionActivities from './components/sections/carousel-activities.jsx';
import SectionPartners from './components/sections/logos-partners.jsx';
import SectionTestimonials from './components/sections/carousel-testimonials.jsx';
import SectionPersonalizeChampagneExperience from './components/sections/personalize-champagne-experience';

const nameSection = ['sectionDomainOverview', 'section18thCentury', 'sectionAnnexRestored', 'sectionRechargeYourself'];

export default function Home() {
    const { t } = useTranslation();

    const [nbPersons, setNbPersons] = useState(2);
    const [stayDates, setStayDates] = useState({
        startDate: moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
        endDate: moment(new Date()).add(3, 'days').format('YYYY-MM-DD')
    });

    const handleSelectChangePersons = (event) => {
        setNbPersons(event.target.value);
    };

    const handleDateChange = (e) => {
        setStayDates(e.date);
    };

    const extractImages = (list) => {
        return list.reduce((acc, item) => {
            if (imageData['home'][item]) {
                acc[item] = imageData['home'][item];
            }
            return acc;
        }, {});
    }

    // Extracting images for carousels
    const listOfPaths = extractImages(nameSection);

    return (
        <div>
            <Helmet>
                <title>{t('home.page')}</title>
                <meta name="description" content={t('home.description')} />
                <meta name="keywords" content={t('home.keywords')} />
            </Helmet>

            <HeaderBanner
                banner={BannerHome}
                stayDates={stayDates}
                handleDateChange={handleDateChange}
                nbPersons={nbPersons}
                handleSelectChangePersons={handleSelectChangePersons}
            />

            <SectionPhotoGallery

            />

            <SectionDomainOverview
                image={imageVignes}
                listOfPaths={listOfPaths}
            />

            <SectionPersonalizeChampagneExperience
                title={t('home.sectionPersonalizeChampagneExperience.title')}
                description={t('home.sectionPersonalizeChampagneExperience.description')}
                services={t('home.sectionPersonalizeChampagneExperience.data', { returnObjects: true })}
            />

            <SectionMainHouseAnnex
                listOfPaths={listOfPaths}
            />

            <SectionGardenPresentation
                listOfPaths={listOfPaths}
                iconPool={iconPool}
                iconBed={iconBed}
                iconTree={iconTree}
                iconPremium={iconPremium}
                iconMultisport={iconMultisport}
                iconSauna={iconSauna}
            />

            <SectionProfessionalConcierge
                ImageServiceBell={ImageServiceBell}
            />

            <SectionServices
                title={t('home.sectionServices.title')}
                services={t('home.sectionServices.data', { returnObjects: true })}
                button={t('home.sectionServices.button')}
            />

            <SectionMap
                map={map}
            />

            <SectionActivities
                title={t('home.sectionActivities.title')}
                description={t('home.sectionActivities.description')}
                data={t('activities.data', { returnObjects: true })}
            />

            <SectionPartners
                title={t('home.sectionOurPartners.title')}
                data={t('home.sectionOurPartners.logos', { returnObjects: true })}
            />

            <SectionTestimonials
                title={t('home.sectionTestimonials.title')}
                data={t('home.sectionTestimonials.data', { returnObjects: true })}
            />
        </div>
    );
}