import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import H3 from '../Titles/h3';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LogoIcon from '../Logos/logo-icon';
import Link from '../Links/link';

const WidgetContact = () => {
    const { t } = useTranslation();

    const [isContentVisible, setIsContentVisible] = useState(false);

    useEffect(() => {
        const isTabletOrMobile = window.innerWidth <= 768;

        if (!isTabletOrMobile) {
            setIsContentVisible(true);
        }
    }, []);

    const toggleContentVisibility = () => {
        setIsContentVisible(!isContentVisible);
    };

    return (
        <div className="fixed bottom-4 right-4 bg-secondary p-3 pb-2 rounded-xl shadow-lg max-w-xs transition-all duration-300 z-50">
            <div className={`transition-all duration-300 overflow-hidden ${isContentVisible ? 'max-h-36 max-w-xs' : 'max-w-0 max-h-0'}`}>

                <div className={`${isContentVisible ? "absolute" : "hidden"} bg-white rounded-full p-2 right-4 -top-11`}>
                    <LogoIcon width={60} height={60} />
                </div>

                <div className={`${isContentVisible ? "opacity-100" : "opacity-0"} transition-all duration-300 delay-300`}>
                    <div className={`${isContentVisible ? "opacity-100" : "opacity-0"} flex items-center justify-between`}>
                        <H3 title={t('widgetContact.title')} theme='white' />
                    </div>
                    <div className={`${isContentVisible ? "opacity-60" : "opacity-0"} text-white font-normal`}>
                        <p className='text-sm' dangerouslySetInnerHTML={{ __html: t('widgetContact.description') }} />
                    </div>
                </div>
            </div>

            <div className='flex justify-between gap-4 items-end'>
                <button
                    onClick={toggleContentVisibility}
                    className="text-white focus:outline-none transform hover:scale-110 transition-transform"
                    aria-label={isContentVisible ? t('widgetContact.toggleButtonLabelDown') : t('widgetContact.toggleButtonLabelUp')}
                >
                    {isContentVisible ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                            />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 15l7-7 7 7"
                            />
                        </svg>
                    )}
                </button>

                <Link link="/contact">
                    <FontAwesomeIcon icon={faPen} className='p-2 rounded-full bg-main text-white cursor-pointer focus:outline-none transform hover:scale-110 transition-transform' />
                </Link>
            </div>
        </div>
    );
};

export default WidgetContact;