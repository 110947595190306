import React from 'react';
import { useTranslation } from "react-i18next";
import H2 from '../../../../components/Titles/h2';
import DescriptionSplit from '../../../../components/Description/description-split';
import FullWidthImage from '../../../../components/Images/image-full-width';

const SectionProfessionalConcierge = ({ ImageServiceBell }) => {
    const { t } = useTranslation();

    return (
        <div className='flex-col lg:flex-row w-10/12 mx-auto mt-12 lg:mt-40 rounded-3xl lg:rounded-bl-[186px] lg:rounded-tr-[186px] bg-secondary p-4 lg:pr-12 lg:pl-24 lg:py-8 flex gap-8 lg:gap-12 lg:gap-20'>
            <div className="w-full">
                <H2 title={t('home.sectionProfessionalConcierge.title')} theme='main' className='mb-6' />
                <DescriptionSplit description={t('home.sectionProfessionalConcierge.description')} theme="light" className='lg:text-sm xl:text-base' />
            </div>
            <div className='hidden lg:flex justify-center' style={{ borderRadius: '0 186px 186px 0' }}>
                <FullWidthImage
                    url={ImageServiceBell}
                    customHeight={250}
                    customWidth={250}
                    alt={t('home.sectionProfessionalConcierge.altImage')}
                    style={{ borderRadius: '0 186px 186px 0' }}
                />
            </div>
        </div>
    );
};

export default SectionProfessionalConcierge;
