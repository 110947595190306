import { useTranslation } from 'react-i18next';

import TitleQuotation from './../components/title-quotation';
import ListOfGuestsQuotation from './../components/list-of-guests';
import Hr from '../../../components/Style/hr';

export default function SecondStepAcceptQuotation({ quotation, returnedData }) {
    const { t } = useTranslation();

    const handleReturnedData = (data) => {
        returnedData(data);
    };

    return (
        <>
            <TitleQuotation title={t('pageQuotation.step2.welcomeMessage')} description={t('pageQuotation.step2.description')} step="2" />
            <Hr />
            <ListOfGuestsQuotation quotation={quotation} returnedData={handleReturnedData} />
        </>
    );
}