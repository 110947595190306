import { useTranslation } from 'react-i18next';

import TitleStatusQuotation from '../components/title-status-quotation';
import Hr from '../../../components/Style/hr';

export default function ModifiedStepAcceptQuotation() {
    const { t } = useTranslation();

    return (
        <div className='px-4 md:px-0'>
            <TitleStatusQuotation title={t('pageQuotation.modified.title')} description={t('pageQuotation.modified.description')} />
            <Hr />
        </div>
    );
}