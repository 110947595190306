export default function UnorderedList({ items }) {
    return (
        <ul className="list-disc pl-4 md:pl-6">
            {items.map((text, index) => (
                <li key={index} className="text-description text-sm md:text-base mb-2">
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                </li>
            ))}
        </ul>
    );
}
