import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Datepicker from '../Datepicker';

export default function DateFieldInput({ label, name, value, minDate = new Date(1900, 0, 1), maxDate = new Date(), error, onChange, displayLabel = false, singleDate = true, blockedDates = [], className = "" }) {
    const { t, i18n } = useTranslation();
    const [selectedDate, setSelectedDate] = useState(value);

    useEffect(() => {
        setSelectedDate(value);
    }, [value]);

    const handleDateChange = (newValues) => {
        setSelectedDate(newValues);
        if (onChange) {
            onChange({ name, date: newValues });
        }
    };

    return (
        <div className='w-full relative'>
            {displayLabel && (
                <label htmlFor={label} className="text-sm text-secondary block mb-2">
                    {t(`formInputs.${label}`)}
                </label>
            )}

            <Datepicker
                value={selectedDate}
                i18n={i18n.language}
                primaryColor={"amber"}
                startWeekOn="mon"
                asSingle={singleDate}
                placeholder={t(`formInputs.${label}`)}
                displayFormat={"DD/MM/YYYY"}
                minDate={minDate}
                maxDate={maxDate}
                inputClassName={`bg-white w-full text-secondary text-sm border ${error ? "border-error" : "border-borderColor"} p-3 pl-10 rounded-lg ${className}`}
                toggleClassName={`absolute text-main left-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed ${className}`}
                onChange={handleDateChange}
                disabledDates={blockedDates}
            />

            {error && (
                <p className="mt-1 ml-1 text-error text-xs">{error}</p>
            )}
        </div>
    );
}
