import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import H2 from "../../../../components/Titles/h2";
import DescriptionSplit from "../../../../components/Description/description-split";
import ActivityCard from "../../../../components/Card/activity-card";

export default function SectionActivities({ title, description, data }) {
    const [displayedData] = useState(data);
    const [currentIndex, setCurrentIndex] = useState(1);

    const handleNext = () => {
        let newIndex = (currentIndex + 1 + displayedData.length) % displayedData.length;
        if (newIndex === displayedData.length - 1) newIndex = 1;

        setCurrentIndex(newIndex);
    };

    const handlePrev = () => {
        let newIndex = (currentIndex - 1 + displayedData.length) % displayedData.length;
        if (newIndex === 0) newIndex = displayedData.length - 2;

        setCurrentIndex(newIndex);
    };

    const getDataSlidesToShow = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth < 576) {
            return { slidesToShow: 1, centerSlidePercentage: 80 };

        } else if (screenWidth < 768) {
            return { slidesToShow: 2, centerSlidePercentage: 60 };

        } else if (screenWidth < 1024) {
            return { slidesToShow: 3, centerSlidePercentage: 40 };

        } else {
            return { slidesToShow: 4, centerSlidePercentage: 30 };
        }
    };

    return (
        <div className="mt-12 lg:mt-24 w-full overflow-hidden">
            <div className="w-full">
                <div className="w-4/5 flex flex-col lg:flex-row m-auto justify-between lg:gap-8 mb-4 lg:mb-6">
                    <div>
                        <H2 title={title} />
                        <DescriptionSplit description={description} />
                    </div>
                    <div className="hidden lg:flex justify-between mt-4 gap-4">
                        <button onClick={handlePrev} className="bg-gray-100 text-gray-500 py-4 px-6 rounded-full">
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <button onClick={handleNext} className="bg-main text-white py-4 px-6 rounded-full">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                </div>

                <div>
                    <Carousel
                        showThumbs={false}
                        showIndicators={false}
                        showStatus={false}
                        selectedItem={currentIndex}
                        onChange={(index) => setCurrentIndex(index)}
                        centerMode={true}
                        centerSlidePercentage={getDataSlidesToShow().centerSlidePercentage}
                        slidesToShow={getDataSlidesToShow().slidesToShow}
                        infiniteLoop={true}
                    >

                        {/* <div className="flex gap-14"> */}
                        {displayedData.map((activity, i) => (
                            <div key={i} className="flex justify-center flex-col p-4 lg:p-8">
                                <ActivityCard activity={activity} />
                            </div>
                        ))}
                        {/* </div> */}
                    </Carousel>
                </div>
            </div>
        </div>
    );
}