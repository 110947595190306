import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

export default function CarouselImages({ listOfImages = [], imagesPaths = [], verticalAlign = "left", horizontalAlign = "bottom" }) {
    const sliderRef = useRef(null);
    const [images, setImages] = useState(listOfImages);

    useEffect(() => {
        const loadImages = async () => {
            if (imagesPaths.length) {
                const loadedImages = await Promise.all(
                    imagesPaths.map(async (path) => {
                        try {
                            const image = await import(`./../../assets/${path.src}`);
                            return { src: image.default, alt: path.alt };
                        } catch (error) {
                            console.error('Error loading image:', error);
                            return null;
                        }
                    })
                );
                setImages(loadedImages.filter((img) => img !== null));
            }
        };

        loadImages();
    }, [imagesPaths, listOfImages.length]);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        responsive: [
            {
                breakpoint: 768, // Adjust the value based on your needs
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const goToNextSlide = () => {
        sliderRef.current.slickNext();
    };

    const goToPrevSlide = () => {
        sliderRef.current.slickPrev();
    };

    const roundedTop = horizontalAlign === "top" && `md:rounded-t${verticalAlign === "left" ? "r" : "l"}-xl`;
    const roundedBottom = horizontalAlign === "bottom" && `rounded-b${verticalAlign === "left" ? "r" : "l"}-xl`;

    const isTabletOrMobile = window.innerWidth <= 768;
    const roundedBottomMobile = horizontalAlign === "top" && isTabletOrMobile && `rounded-b${verticalAlign === "left" ? "r" : "l"}-xl`;

    const containerClassName = `relative bg-white p-3 pb-1 md:p-4 md:pb-2 ${verticalAlign === "left" ? "pl-0" : "pr-0"} shadow-lg ${verticalAlign === "left" ? "rounded-r-xl" : "rounded-l-xl"}`;

    return (
        <div className={containerClassName}>
            <Slider ref={sliderRef} {...settings}>
                {images.map((image, index) => (
                    <div key={index} className={`${verticalAlign === "left" ? "rounded-r-xl" : "rounded-l-xl"} overflow-hidden`}>
                        <img src={image.src} alt={image.alt} className="w-full h-auto" />
                    </div>
                ))}
            </Slider>
            {images.length > 1 &&
                <div className={`flex flex-col items-center absolute ${horizontalAlign === "bottom" ? "bottom-3 md:bottom-4" : "bottom-3 md:top-4"} ${verticalAlign === "left" ? "right-3 md:right-4" : "left-3 md:left-4"}`}>
                    <button onClick={goToNextSlide} className={`bg-secondary text-white px-4 md:px-6 py-1 md:py-2 ${roundedTop}`}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </button>

                    <button onClick={goToPrevSlide} className={`bg-secondary text-white px-4 md:px-6 py-1 md:py-2 ${roundedBottom} ${roundedBottomMobile}`}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                </div>
            }
        </div>
    );
}
