import { useEffect, useState } from "react";
import DescriptionSplit from "../../../components/Description/description-split";
import Hr from "../../../components/Style/hr";
import H3 from "../../../components/Titles/h3";
import serviceShadow from "../../../assets/images/home/serviceShadow.svg"

export default function ServiceCard({ title, description, path = "sectionServices", img }) {
    const [displayedImg, setDisplayedImg] = useState(null);

    useEffect(() => {
        const loadImage = async () => {
            try {
                const imageModule = await import(`./../../../assets/images/home/${path}/${img}`);
                const image = imageModule.default;
                setDisplayedImg(image);
            } catch (error) {
                console.error('Error loading image:', error);
            }
        };

        loadImage();
    }, [img, path]);

    return (
        <div className="flex h-full min-h-450" >
            <div className="relative rounded-xl">
                <img src={displayedImg} alt='TODO' className="absolute top-0 left-0 w-full h-full object-cover rounded-xl flex-grow" />
                <img src={serviceShadow} alt='TODO' className="absolute bottom-0 left-0 w-full h-full object-cover rounded-xl flex-grow" />

                <div className="relative z-10 p-4 pt-40 flex flex-col min-h-450 justify-end">
                    <H3 title={title} theme="white" />
                    <Hr borderColor="main" opacity="100" />
                    <DescriptionSplit description={description} theme="light" opacity="100" className="md:text-sm" />
                </div>
            </div>
        </div>
    );
}
