import React from 'react';

export default function SvgImage({ SvgComponent, alt, customHeight, customWidth, className = "", color = "currentColor" }) {
    const imgStyle = {
        height: customHeight || 'auto', // Set default to 'auto' if not provided
        width: customWidth || 'auto',  // Set default to 'auto' if not provided
        color: color, // Color for SVG fill, assuming SVG uses 'currentColor' for fill and stroke
    };

    if (!SvgComponent) return null;

    return (
        <div style={imgStyle} className={`bg-contain bg-no-repeat bg-center ${className}`}>
            <SvgComponent alt={alt} style={{ width: '100%', height: '100%' }} />
        </div>
    );
}
