import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import H2 from '../../../components/Titles/h2';
import DescriptionSplit from '../../../components/Description/description-split';
import { faBath, faBed, faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullWidthImage from '../../../components/Images/image-full-width';

export default function AccommodationSelected({ accommodation }) {
    const [images, setImages] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        const loadImages = async () => {
            const loadedImages = await Promise.all(
                accommodation.map(async (name) => {
                    try {
                        const image = await import(`./../../../assets/images/reservation/${name}.jpg`);
                        return image.default;
                    } catch (error) {
                        console.error('Error loading image:', error);
                        return null;
                    }
                })
            );

            setImages(loadedImages.filter((image) => image !== null));
        };

        loadImages();
    }, [accommodation]);

    return (
        <div className="w-full">
            {accommodation.map((name, index) => (
                <div key={name}>
                    <div className='flex gap-4' key={name}>
                        <div className='container relative w-24 h-24 m-auto'>
                            {/* <img alt={t(`reservation.accommodation.${name}.alt`)} src={images[index]} className='w-full h-full object-cover object-center' /> */}
                            <FullWidthImage url={images[index]} alt={t(`reservation.accommodation.${name}.alt`)} customHeight={96} customWidth={96} className="rounded-xl" />
                        </div>
                        <div className='w-auto'>
                            <H2 title={t(`reservation.accommodation.${name}.title`)} />
                            <DescriptionSplit description={t(`reservation.accommodation.${name}.description`)} className='text-sm' />
                            <div className='flex justify-between'>
                                <div>
                                    <FontAwesomeIcon icon={faUser} className='text-xs text-main' />
                                    <span className='text-xs ml-1'>{t(`reservation.accommodation.${name}.nbPersons`)}</span>
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faBed} className='text-xs text-main' />
                                    <span className='text-xs ml-1'>{t(`reservation.accommodation.${name}.nbBeds`)}</span>
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faBath} className='text-xs text-main' />
                                    <span className='text-xs ml-1'>{t(`reservation.accommodation.${name}.nbBathroom`)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(accommodation.length > 1 && accommodation.length !== index + 1) &&
                        <div className='flex items-center justify-center w-full' key={index}>
                            <FontAwesomeIcon icon={faPlus} className='font-bold text-3xl my-4' />
                        </div>
                    }
                </div>
            ))}
        </div>
    );
}