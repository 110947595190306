import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import React, { useCallback, useContext, useState } from "react";

import { BG_COLOR, TEXT_COLOR } from "../../constants";
import DatepickerContext from "../../contexts/DatepickerContext";
import { formatDate, nextMonth, previousMonth, classNames as cn } from "../../helpers";
import { Period } from "../../types";
import { useTranslation } from "react-i18next";

dayjs.extend(isBetween);

interface Props {
    calendarData: {
        date: dayjs.Dayjs;
        days: {
            previous: number[];
            current: number[];
            next: number[];
        };
    };
    onClickPreviousDays: (day: number) => void;
    onClickDay: (day: number, month: number, year: number, beforeOfAfterBlockedDate: string) => void;
    onClickNextDays: (day: number) => void;
}

const Days: React.FC<Props> = ({
    calendarData,
    onClickPreviousDays,
    onClickDay,
    onClickNextDays
}) => {
    // Contexts
    const {
        primaryColor,
        period,
        changePeriod,
        dayHover,
        changeDayHover,
        minDate,
        maxDate,
        disabledDates
    } = useContext(DatepickerContext);

    // Functions
    const currentDateClass = useCallback(
        (item: number) => {
            const itemDate = `${calendarData.date.year()}-${calendarData.date.month() + 1}-${item >= 10 ? item : "0" + item}`;
            if (formatDate(dayjs()) === formatDate(dayjs(itemDate)))
                return TEXT_COLOR["500"][primaryColor as keyof (typeof TEXT_COLOR)["500"]];
            return "";
        },
        [calendarData.date, primaryColor]
    );

    const { t } = useTranslation();

    const [message, setMessage] = useState("");
    const [activeDay, setActiveDay] = useState<number | null>(null);  // Track the day number for showing the message
    const [beforeOfAfterBlockedDate, setBeforeOfAfterBlockedDate] = useState("before");
    const [lastClickedDate, setLastClickedDate] = useState<string | null>(null);

    const activeDateData = useCallback(
        (day: number) => {
            const fullDay = `${calendarData.date.year()}-${calendarData.date.month() + 1}-${day}`;
            let className = "";

            if (dayjs(fullDay).isSame(period.start) && dayjs(fullDay).isSame(period.end)) {
                className = ` ${BG_COLOR["500"][primaryColor]} text-white font-medium rounded-full`;
            } else if (dayjs(fullDay).isSame(period.start)) {
                className = ` ${BG_COLOR["500"][primaryColor]} text-white font-medium ${dayjs(fullDay).isSame(dayHover) && !period.end
                    ? "rounded-full"
                    : "rounded-l-full"
                    }`;
            } else if (dayjs(fullDay).isSame(period.end)) {
                className = ` ${BG_COLOR["500"][primaryColor]} text-white font-medium ${dayjs(fullDay).isSame(dayHover) && !period.start
                    ? "rounded-full"
                    : "rounded-r-full"
                    }`;
            }

            return {
                active: dayjs(fullDay).isSame(period.start) || dayjs(fullDay).isSame(period.end),
                className: className
            };
        },
        [calendarData.date, dayHover, period.end, period.start, primaryColor]
    );

    const hoverClassByDay = useCallback(
        (day: number) => {
            let className = currentDateClass(day);
            const fullDay = `${calendarData.date.year()}-${calendarData.date.month() + 1}-${day >= 10 ? day : "0" + day}`;

            if (period.start && period.end) {
                if (dayjs(fullDay).isBetween(period.start, period.end, "day", "[)")) {
                    return ` ${BG_COLOR["100"][primaryColor]} ${currentDateClass(day)} dark:bg-white/10`;
                }
            }

            if (!dayHover) {
                return className;
            }

            if (period.start && dayjs(fullDay).isBetween(period.start, dayHover, "day", "[)")) {
                className = ` ${BG_COLOR["100"][primaryColor]} ${currentDateClass(day)} dark:bg-white/10`;
            }

            if (period.end && dayjs(fullDay).isBetween(dayHover, period.end, "day", "[)")) {
                className = ` ${BG_COLOR["100"][primaryColor]} ${currentDateClass(day)} dark:bg-white/10`;
            }

            if (dayHover === fullDay) {
                const bgColor = BG_COLOR["500"][primaryColor];
                className = ` transition-all duration-500 text-white font-medium ${bgColor} ${period.start ? "rounded-r-full" : "rounded-l-full"}`;
            }

            return className;
        },
        [calendarData.date, currentDateClass, dayHover, period.end, period.start, primaryColor]
    );

    const isDateTooEarly = useCallback(
        (day: number, type: "current" | "previous" | "next") => {
            if (!minDate) {
                return false;
            }
            const object = {
                previous: previousMonth(calendarData.date),
                current: calendarData.date,
                next: nextMonth(calendarData.date)
            };
            const newDate = object[type as keyof typeof object];
            const formattedDate = newDate.set("date", day);
            return dayjs(formattedDate).isSame(dayjs(minDate), "day")
                ? false
                : dayjs(formattedDate).isBefore(dayjs(minDate));
        },
        [calendarData.date, minDate]
    );

    const isDateTooLate = useCallback(
        (day: number, type: "current" | "previous" | "next") => {
            if (!maxDate) {
                return false;
            }
            const object = {
                previous: previousMonth(calendarData.date),
                current: calendarData.date,
                next: nextMonth(calendarData.date)
            };
            const newDate = object[type as keyof typeof object];
            const formattedDate = newDate.set("date", day);
            return dayjs(formattedDate).isSame(dayjs(maxDate), "day")
                ? false
                : dayjs(formattedDate).isAfter(dayjs(maxDate));
        },
        [calendarData.date, maxDate]
    );

    const isDisabled = useCallback((date: any) => {
        return disabledDates?.some(({ startDate }) => date.isSame(startDate, 'day'));

    }, [disabledDates]);

    const isDateDisabled = useCallback(
        (day: number, type: "current" | "previous" | "next") => {
            const date = calendarData.date.clone().month(calendarData.date.month() + (type === 'next' ? 1 : type === 'previous' ? -1 : 0)).date(day);

            if (isDateTooEarly(day, type) || isDateTooLate(day, type)) {
                return true;
            }

            const object = {
                previous: previousMonth(calendarData.date),
                current: calendarData.date,
                next: nextMonth(calendarData.date)
            };

            const newDate = object[type as keyof typeof object];
            const formattedDate = `${newDate.year()}-${newDate.month() + 1}-${day >= 10 ? day : "0" + day}`;

            if (!disabledDates || (Array.isArray(disabledDates) && !disabledDates.length)) {
                return false;
            }

            if (isDisabled(date)) {
                return true;
            }

            let matchingCount = 0;
            disabledDates?.forEach(dateRange => {
                const adjustedEndDate = dayjs(dateRange.endDate).subtract(1, 'day');

                if (
                    dayjs(formattedDate).isAfter(dateRange.startDate) &&
                    dayjs(formattedDate).isBefore(adjustedEndDate)
                ) {
                    matchingCount++;
                }
                if (
                    dayjs(formattedDate).isSame(dateRange.startDate) ||
                    dayjs(formattedDate).isSame(adjustedEndDate)
                ) {
                    matchingCount++;
                }
            });

            // Block dates according to the rules for Fridays, Saturdays, and Sundays
            if (period.start && !period.end) {
                const startDate = dayjs(period.start);
                const dayOfWeek = startDate.day();

                if (dayOfWeek === 5) { // Friday
                    const afterDate = startDate.add(1, 'day');
                    if (date.isSame(afterDate, 'day')) {
                        return true;
                    }
                } else if (dayOfWeek === 6) { // Saturday
                    const beforeDate = startDate.subtract(1, 'day');
                    const afterDate = startDate.add(1, 'day');
                    if (date.isSame(beforeDate, 'day') || date.isSame(afterDate, 'day')) {
                        return true;
                    }
                } else if (dayOfWeek === 0) { // Sunday
                    const beforeDate = startDate.subtract(1, 'day');
                    if (date.isSame(beforeDate, 'day')) {
                        return true;
                    }
                }
            }

            if (period.end && !period.start) {
                const endDate = dayjs(period.end);
                const dayOfWeek = endDate.day();

                if (dayOfWeek === 5) { // Friday
                    const afterDate = endDate.add(1, 'day');
                    if (date.isSame(afterDate, 'day')) {
                        return true;
                    }
                } else if (dayOfWeek === 6) { // Saturday
                    const beforeDate = endDate.subtract(1, 'day');
                    const afterDate = endDate.add(1, 'day');
                    if (date.isSame(beforeDate, 'day') || date.isSame(afterDate, 'day')) {
                        return true;
                    }
                } else if (dayOfWeek === 0) { // Sunday
                    const beforeDate = endDate.subtract(1, 'day');
                    if (date.isSame(beforeDate, 'day')) {
                        return true;
                    }
                }
            }

            return matchingCount > 0;
        },
        [calendarData.date, isDateTooEarly, isDateTooLate, disabledDates, isDisabled, period]
    );

    const buttonClass = useCallback(
        (day: number, type: "current" | "next" | "previous") => {
            const baseClass = "flex items-center justify-center w-12 h-12 lg:w-10 lg:h-10";
            if (type === "current") {
                return cn(
                    baseClass,
                    !activeDateData(day).active
                        ? hoverClassByDay(day)
                        : activeDateData(day).className,
                    isDateDisabled(day, type) && "line-through"
                );
            }
            return cn(baseClass, isDateDisabled(day, type) && "line-through", "text-gray-400");
        },
        [activeDateData, hoverClassByDay, isDateDisabled]
    );

    const checkIfHoverPeriodContainsDisabledPeriod = useCallback(
        (hoverPeriod: Period, interval: string = "before") => {
            if (!Array.isArray(disabledDates)) {
                return false;
            }

            let { start, end } = hoverPeriod;

            if (dayjs(start).isAfter(dayjs(end))) {
                [start, end] = [end, start];
            }

            hoverPeriod.start = start;
            hoverPeriod.end = end;

            let containsDisabledPeriod = false;

            for (const { startDate, endDate } of disabledDates) {
                if ((dayjs(start).isBetween(startDate, endDate) || dayjs(end).isBetween(startDate, endDate)) ||
                    ((dayjs(start).isBefore(startDate) || dayjs(start).isSame(startDate)) && dayjs(end).isAfter(startDate))
                    // || dayjs(end).isSame(startDate)
                ) {
                    const selectedDate = beforeOfAfterBlockedDate === "before" ? startDate : endDate;
                    const dateString = dayjs(selectedDate).format('YYYY-M-DD');

                    setBeforeOfAfterBlockedDate(interval);
                    changeDayHover(dateString);
                    setMessage(t('reservation.blockedDate'))

                    containsDisabledPeriod = true;
                }
            }

            return containsDisabledPeriod;
        },
        [disabledDates, changeDayHover, beforeOfAfterBlockedDate, t]
    );

    const getMetaData = useCallback(() => {
        return {
            previous: previousMonth(calendarData.date),
            current: calendarData.date,
            next: nextMonth(calendarData.date)
        };
    }, [calendarData.date]);

    const changePeriodDueToDisabledDates = useCallback((start: any, end: any, interval: string) => {
        setBeforeOfAfterBlockedDate(interval);

        let tmpStart = start;
        let tmpEnd = end;

        if (dayjs(tmpStart).isAfter(dayjs(tmpEnd))) {
            start = tmpEnd;
            end = tmpStart;
        }

        if (disabledDates) {
            for (let i = 0; i < disabledDates.length; i++) {
                if (dayjs(start).isSame(disabledDates[i].startDate) && dayjs(end).isAfter(disabledDates[i].startDate)) {
                    if (interval === "before") {
                        return {
                            start: start,
                            end: start
                        };
                    } else {
                        return {
                            start: dayjs(disabledDates[i].endDate).format('YYYY-M-DD'),
                            end: end
                        };
                    }

                } else if (dayjs(start).isBefore(disabledDates[i].startDate) &&
                    dayjs(end).isAfter(disabledDates[i].startDate)) {
                    if (interval === "before") {
                        const startDateString = dayjs(disabledDates[i].startDate).format('YYYY-M-DD');

                        return {
                            start: start,
                            end: startDateString
                        };

                    } else {
                        const endDateString = dayjs(disabledDates[i].endDate).format('YYYY-M-DD');

                        return {
                            start: endDateString,
                            end: end
                        };
                    }
                }
            }
        };

        return {
            start: start,
            end: end
        };

    }, [disabledDates]);

    const hoverDay = useCallback(
        (day: number, type: "current" | "previous" | "next", index: any) => {
            const object = getMetaData();
            const newDate = object[type as keyof typeof object];
            let dateString = `${newDate.year()}-${newDate.month() + 1}-${day >= 10 ? day : "0" + day}`;

            if (period.start && !period.end) {
                const hoverPeriod = { ...period, end: dateString };
                const interval = dayjs(dateString).isAfter(dayjs(period.start)) ? "before" : "after";

                const newData = changePeriodDueToDisabledDates(period.start, dateString, interval);

                hoverPeriod.start = newData.start;
                hoverPeriod.end = newData.end;

                if (!checkIfHoverPeriodContainsDisabledPeriod(hoverPeriod)) {
                    if (dayjs(dateString).isBefore(dayjs(period.start))) {
                        changePeriod({
                            start: null,
                            end: period.start
                        });
                    } else {
                        changePeriod({
                            start: period.start,
                            end: null
                        });
                    }
                }

                if (dayjs(dateString).isSame(dayjs(period.start))) {
                    const dayOfWeek = dayjs(period.start).day();
                    if (dayOfWeek === 5 || dayOfWeek === 6) { // Friday or Saturday
                        setMessage(t('reservation.minimumTwoNights'));

                    } else {
                        setMessage(t('reservation.minimumOneNight'));
                    }
                    setActiveDay(day);

                    const newInterval = dayjs(dateString).isAfter(dayjs(period.start)) ? "before" : "after";
                    const newHoverData = changePeriodDueToDisabledDates(period.start, dateString, newInterval);

                    hoverPeriod.start = newHoverData.start;
                    hoverPeriod.end = newHoverData.end;

                    if (!checkIfHoverPeriodContainsDisabledPeriod(hoverPeriod)) {
                        changePeriod({
                            start: null,
                            end: period.start
                        });
                    }

                } else {
                    setMessage("");  // Clear message
                    setActiveDay(null);  // Clear active day
                }

                if (!checkIfHoverPeriodContainsDisabledPeriod(hoverPeriod)) {
                    if (dayjs(period.start).isSame(dayjs(hoverPeriod.start))) {
                        dateString = newData.end;
                    } else {
                        dateString = newData.start;
                    }

                    changeDayHover(dateString);
                }

                if (isDisabled(dayjs(period.start)) && dayjs(dateString).isAfter(dayjs(period.start))) {
                    changeDayHover(null);
                }
            }

            if (!period.start && period.end) {
                const hoverPeriod = { ...period, start: dateString };
                const interval = dayjs(dateString).isAfter(dayjs(period.end)) ? "before" : "after";

                if (dayjs(dateString).isAfter(dayjs(period.end))) {
                    const newData = changePeriodDueToDisabledDates(dateString, period.end, interval);

                    dateString = newData.end;

                    hoverPeriod.start = newData.start;
                    hoverPeriod.end = newData.end;

                    if (!checkIfHoverPeriodContainsDisabledPeriod(hoverPeriod)) {
                        changePeriod({
                            start: period.end,
                            end: null
                        });
                    }
                }

                if (dayjs(dateString).isSame(dayjs(period.end))) {
                    const dayOfWeek = dayjs(period.end).day();
                    if (dayOfWeek === 5 || dayOfWeek === 6) { // Friday or Saturday
                        setMessage(t('reservation.minimumTwoNights'));

                    } else {
                        setMessage(t('reservation.minimumOneNight'));
                    }

                    setActiveDay(day);

                } else {
                    setMessage("");  // Clear message
                    setActiveDay(null);  // Clear active day
                }

                if (!checkIfHoverPeriodContainsDisabledPeriod(hoverPeriod, interval)) {
                    changeDayHover(dateString);
                }

                if (isDisabled(dayjs(period.end)) && dayjs(dateString).isAfter(dayjs(period.end))) {
                    changeDayHover(null);
                }
            }

            if (period.start && period.end) {
                changeDayHover(null);
            }

            // Check if hovering over a disabled date due to the 2-night minimum stay requirement
            const date = calendarData.date.clone().month(calendarData.date.month() + (type === 'next' ? 1 : type === 'previous' ? -1 : 0)).date(day);
            const dayOfWeek = date.day();
            if (isDateDisabled(day, type) && !checkIfHoverPeriodContainsDisabledPeriod({ start: period.start, end: period.end })) {
                if (dayOfWeek === 5) { // Friday
                    setMessage(t('reservation.minimumTwoNights'));
                    setActiveDay(day);

                } else if (dayOfWeek === 6) { // Saturday
                    setMessage(t('reservation.minimumTwoNights'));
                    setActiveDay(day);

                } else if (dayOfWeek === 0) { // Sunday
                    setMessage(t('reservation.minimumTwoNights'));
                    setActiveDay(day);

                } else {
                    setMessage(t('reservation.minimumOneNight'));
                    setActiveDay(day);
                }
            }
        },
        [
            t,
            changePeriodDueToDisabledDates,
            changeDayHover,
            changePeriod,
            checkIfHoverPeriodContainsDisabledPeriod,
            getMetaData,
            isDisabled,
            period,
            isDateDisabled,
            calendarData.date
        ]
    );


    const isDayStartOrEndOfDisabled = useCallback((date: any) => {
        return disabledDates?.some(({ startDate, endDate }) =>
            date.isSame(startDate, 'day') || date.isSame(endDate, 'day')
        );

    }, [disabledDates]);

    const getDateClass = (day: number, type: "next" | "previous" | "current") => {
        const date = calendarData.date.clone().month(calendarData.date.month() + (type === 'next' ? 1 : type === 'previous' ? -1 : 0)).date(day);
        let classes = `transition duration-300 ease-in-out`;

        if (formatDate(date) === formatDate(dayjs())) {
            classes += ` text-${primaryColor}-700`; // Highlight today's date
        }

        if (isDisabled(date)) {
            // classes += ' text-gray-500';

        } else if (isDateDisabled(day, type)) {
            classes += ' opacity-60';

        } else {
            classes += ' font-semibold';
        }

        return classes;
    };

    const handleClickDay = useCallback(
        (day: number, type: "previous" | "current" | "next") => {
            function continueClick() {
                if (type === "previous") {
                    onClickPreviousDays(day);
                }

                if (type === "current") {
                    onClickDay(day, date.month() + 1, date.year(), beforeOfAfterBlockedDate);
                }

                if (type === "next") {
                    onClickNextDays(day);
                }
            }

            const object = getMetaData();
            const newDate = object[type as keyof typeof object];
            const dateString = `${newDate.year()}-${newDate.month() + 1}-${day >= 10 ? day : "0" + day}`;

            if (dateString === lastClickedDate) {
                console.log("This date has already been clicked.");
                return;
            }

            setLastClickedDate(dateString);

            const date = calendarData.date.clone().month(calendarData.date.month() + (type === 'next' ? 1 : type === 'previous' ? -1 : 0)).date(day);
            const isStart = disabledDates?.some(({ startDate }) => date.isSame(startDate, 'day'));

            if (isDayStartOrEndOfDisabled(date) && isStart) {
                setMessage(t('reservation.onlyDepartures'));
                setActiveDay(day);  // Set active day to show the message above it

            } else {
                setMessage("");  // Clear message
                setActiveDay(null);  // Clear active day
            }

            // Block the dates just before and just after the selected date based on the day of the week
            if (period.start && !period.end) {
                const startDate = dayjs(dateString).format('YYYY-MM-DD');
                let endDate;

                const dayOfWeek = dayjs(startDate).day();
                if (dayOfWeek === 5) { // Friday
                    endDate = dayjs(startDate).add(1, 'day').format('YYYY-MM-DD');
                } else if (dayOfWeek === 6) { // Saturday
                    endDate = dayjs(startDate).add(2, 'days').format('YYYY-MM-DD');
                } else if (dayOfWeek === 0) { // Sunday
                    endDate = dayjs(startDate).add(1, 'day').format('YYYY-MM-DD');
                } else {
                    endDate = dayjs(startDate).add(1, 'day').format('YYYY-MM-DD');
                }

                changePeriod({
                    start: startDate,
                    end: endDate
                });

                setBeforeOfAfterBlockedDate("after");
                changeDayHover(null);
            }

            if (period.end && !period.start) {
                const endDate = dayjs(dateString).format('YYYY-MM-DD');
                let startDate;

                const dayOfWeek = dayjs(endDate).day();
                if (dayOfWeek === 5) { // Friday
                    startDate = dayjs(endDate).subtract(1, 'day').format('YYYY-MM-DD');
                } else if (dayOfWeek === 6) { // Saturday
                    startDate = dayjs(endDate).subtract(2, 'days').format('YYYY-MM-DD');
                } else if (dayOfWeek === 0) { // Sunday
                    startDate = dayjs(endDate).subtract(1, 'day').format('YYYY-MM-DD');
                } else {
                    startDate = dayjs(endDate).subtract(1, 'day').format('YYYY-MM-DD');
                }

                changePeriod({
                    start: startDate,
                    end: endDate
                });

                setBeforeOfAfterBlockedDate("before");
                changeDayHover(null);
            }

            if (type === 'previous') onClickPreviousDays(day);
            if (type === 'current') onClickDay(day, date.month() + 1, date.year(), beforeOfAfterBlockedDate);
            if (type === 'next') onClickNextDays(day);

            if (disabledDates?.length) {
                if (period.start && !period.end) {
                    setLastClickedDate(null);
                    dayjs(dateString).isSame(dayHover) && continueClick();

                } else if (!period.start && period.end) {
                    setLastClickedDate(null);
                    dayjs(dateString).isSame(dayHover) && continueClick();

                } else {
                    continueClick();
                }
            } else {
                continueClick();
            }
        },
        [
            lastClickedDate,
            beforeOfAfterBlockedDate,
            isDayStartOrEndOfDisabled,
            calendarData.date,
            t,
            dayHover,
            disabledDates,
            getMetaData,
            onClickDay,
            onClickNextDays,
            onClickPreviousDays,
            period.end,
            period.start,
            changePeriod,
            changeDayHover
        ]
    );

    return (
        <div className="grid grid-cols-7 gap-y-0.5 my-1">
            {calendarData.days.previous.map((day, index) => (
                <div key={`prev-${day}`} className="relative">
                    <button
                        type="button"
                        key={index}
                        disabled={isDateDisabled(day, "previous")}
                        className={`${buttonClass(day, "previous")}  ${getDateClass(day, "previous")}`}
                        onClick={() => handleClickDay(day, "previous")}
                        onMouseOver={() => {
                            hoverDay(day, "previous", index);
                        }}
                    >
                        {day}
                    </button>
                </div>
            ))}

            {calendarData.days.current.map((day, index) => (
                <div key={`curr-${day}`} className="relative">
                    {activeDay === day && message && (
                        <div className="absolute -top-10 w-32 left-1/2 transform -translate-x-1/2 text-center py-1 text-sm text-white bg-black rounded shadow-lg before:content-[''] before:absolute before:top-full before:left-1/2 before:w-0 before:h-0 before:border-t-[6px] before:border-t-black before:border-x-[6px] before:border-x-transparent before:border-b-0 before:-translate-x-1/2">
                            {message}
                        </div>
                    )}
                    <button
                        type="button"
                        key={index}
                        disabled={isDateDisabled(day, "current")}
                        className={`${buttonClass(day, "current")} ${getDateClass(day, "current")}`}
                        onClick={() => handleClickDay(day, "current")}
                        onMouseOver={() => {
                            hoverDay(day, "current", index);
                        }}
                    >
                        {day}
                    </button>
                </div>
            ))}

            {calendarData.days.next.map((day, index) => (
                <div key={`next-${day}`} className="relative">
                    <button
                        type="button"
                        key={index}
                        disabled={isDateDisabled(day, "next")}
                        className={`${buttonClass(day, "next")} ${getDateClass(day, "next")}`}
                        onClick={() => handleClickDay(day, "next")}
                        onMouseOver={() => {
                            hoverDay(day, "next", index);
                        }}
                    >
                        {day}
                    </button>
                </div>
            ))}
        </div>
    );
};

export default Days;