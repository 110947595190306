import { useTranslation } from 'react-i18next';

import TitleQuotation from './../components/title-quotation';

import Hr from '../../../components/Style/hr';
import DetailPaiementQuotation from '../components/detail-paiement-quotation';

export default function DepositPaidStepQuotation({ quotation, returnedData }) {
    const { t } = useTranslation();

    const handleReturnedData = (data) => {
        returnedData(data);
    };

    return (
        <>
            <TitleQuotation title={t('pageQuotation.stepDepositPaid.welcomeMessage')} description={t('pageQuotation.stepDepositPaid.description')} step="3" />
            <Hr />
            <DetailPaiementQuotation selectedCard="payLeftToPay" quotation={quotation} returnedData={handleReturnedData} />

            {/* <PaiementQuotation quotation={quotation} returnedData={handleReturnedData} /> */}
        </>
    );
}