import React, { useRef } from 'react';
import { useTranslation } from "react-i18next";
import CarouselImages from '../../../../components/Carousel/carousel-images';
import H2 from '../../../../components/Titles/h2';
import DescriptionSplit from '../../../../components/Description/description-split';
import Icon from '../../../../components/Images/icon';
import useIntersectionObserver from '../../../../utils/hooks/intersection-observer';

const SectionGardenPresentation = ({ listOfPaths, iconPool, iconBed, iconTree, iconPremium, iconMultisport, iconSauna }) => {
    const { t } = useTranslation();

    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const iconsRef = useRef(null);

    useIntersectionObserver(
        [titleRef, descriptionRef, iconsRef],
        ["animate-fade-in-right", "animate-fade-in-up", "animate-fade-in-up"]
    );

    return (
        <div className="mt-12 lg:mt-40 flex flex-col lg:flex-row items-center">
            <div className="w-10/12 mr-[16.666%] lg:w-1/2 lg:mr-0">
                <CarouselImages imagesPaths={listOfPaths.sectionRechargeYourself} verticalAlign="left" horizontalAlign="bottom" />
            </div>
            <div className="lg:w-1/2 p-6 lg:pr-24 lg:py-0 lg:pl-12">
                <div ref={titleRef} className="opacity-0 mb-6">
                    <H2 title={t('home.sectionRechargeYourself.title')} />
                </div>
                <div ref={descriptionRef} className="opacity-0 mb-6">
                    <DescriptionSplit description={t('home.sectionRechargeYourself.description')} />
                </div>

                <div ref={iconsRef} className="opacity-0 grid grid-cols-2 gap-8 lg:gap-12 items-center">
                    {/* Individual feature items */}
                    <div>
                        <div className="flex items-left justify-left gap-4">
                            <Icon icon={iconPool} />
                            <span className="font-bold text-2xl text-secondary">2</span>
                        </div>
                        <DescriptionSplit description={t('home.sectionRechargeYourself.data.pool')} className='pt-2' />
                    </div>
                    <div>
                        <div className="flex items-left justify-left gap-4">
                            <Icon icon={iconBed} />
                            <span className="font-bold text-2xl text-secondary">22</span>
                        </div>
                        <DescriptionSplit description={t('home.sectionRechargeYourself.data.beds')} className='pt-2' />
                    </div>
                    <div>
                        <div className="flex items-left justify-left gap-4">
                            <Icon icon={iconTree} />
                            <span className="font-bold text-2xl text-secondary">10k m²</span>
                        </div>
                        <DescriptionSplit description={t('home.sectionRechargeYourself.data.ground')} className='pt-2' />
                    </div>
                    <div>
                        <div className="flex items-left justify-left gap-4">
                            <Icon icon={iconPremium} />
                            <span className="font-bold text-2xl text-secondary">10</span>
                        </div>
                        <DescriptionSplit description={t('home.sectionRechargeYourself.data.suits')} className='pt-2' />
                    </div>
                    <div>
                        <div className="flex items-left justify-left gap-4">
                            <Icon icon={iconMultisport} />
                            <span className="font-bold text-2xl text-secondary">300 m²</span>
                        </div>
                        <DescriptionSplit description={t('home.sectionRechargeYourself.data.multisport')} className='pt-2' />
                    </div>
                    <div>
                        <div className="flex items-left justify-left gap-4">
                            <Icon icon={iconSauna} />
                            <span className="font-bold text-2xl text-secondary">2</span>
                        </div>
                        <DescriptionSplit description={t('home.sectionRechargeYourself.data.sauna')} className='pt-2' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionGardenPresentation;
