import React, { useCallback, useEffect, useState } from 'react';
import imageData from './../../../../utils/imports/import-all-images.json'; // Import your flattened JSON file
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"; // Import styles for ImageGallery
import "./photo-gallery.scss"; // Import your custom styles

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCamera } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const SectionPhotoGallery = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);

    const { t } = useTranslation();

    const toggleGallery = () => {
        if (isOpen) {
            setModalVisible(false);
            setTimeout(() => {
                setIsOpen(false);
            }, 300);
        } else {
            setIsOpen(true);
            setTimeout(() => {
                setModalVisible(true);
            }, 10);
        }
    };

    const handleBackgroundClick = (e) => {
        if (e.target.id === "gallery-background") {
            setModalVisible(false);
            setTimeout(() => {
                setIsOpen(false);
            }, 300);
        }
    };

    // Function to dynamically import an image based on its path
    const loadImage = async (imgPath) => {
        try {
            const imageModule = await import(`./../../../../assets/${imgPath}`);
            return imageModule.default;
        } catch (error) {
            console.error('Error loading image:', error);
            return null;
        }
    };

    const loadAllImages = useCallback(async () => {
        const loadedImages = await Promise.all(
            imageData.map(async (imgObj) => {
                const image = await loadImage(imgObj.src);
                if (image) {
                    return {
                        original: image,
                        thumbnail: image,
                    };
                }
                return null;
            })
        );
        setImages(loadedImages.filter((img) => img !== null));
    }, []);

    useEffect(() => {
        loadAllImages();
    }, [loadAllImages]);

    return (
        <div className="mt-6 ld: mt-12 p-6 flex justify-center align-center">
            {/* Button to open gallery */}
            <button
                className="bg-main text-white px-4 py-3 rounded-xl flex items-center space-x-2"
                onClick={toggleGallery}
            >
                <FontAwesomeIcon icon={faCamera} />
                <span>{t('home.sectionPhotoGallery.button')}</span>
            </button>

            {isOpen && (
                <div id="gallery-background" className={`fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center ${modalVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300 z-99999`} onClick={handleBackgroundClick}>
                    <div className={`bg-white p-4 rounded-lg shadow-lg max-w-4xl max-h-screen overflow-y-auto transform ${modalVisible ? 'scale-100' : 'scale-95'} transition-transform duration-300`}>
                        <div className="flex justify-between items-center flex-row-reverse">
                            <button
                                className="text-gray-500 hover:text-gray-600 transform hover:scale-125 transition duration-200"
                                onClick={toggleGallery}>
                                <FontAwesomeIcon icon={faXmark} />
                            </button>
                        </div>
                        <div className="mt-4">
                            <ImageGallery items={images} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SectionPhotoGallery;