import React, { useRef } from 'react';
import { useTranslation } from "react-i18next";
import CarouselImages from '../../../../components/Carousel/carousel-images';
import H2 from '../../../../components/Titles/h2';
import DescriptionSplit from '../../../../components/Description/description-split';
import UnorderedList from '../../../../components/List/unordered-list';
import LogoIcon from '../../../../components/Logos/logo-icon';
import useIntersectionObserver from '../../../../utils/hooks/intersection-observer';

const SectionMainHouseAnnex = ({ listOfPaths }) => {
    const { t } = useTranslation();
    const titleRefHouse = useRef(null);
    const descriptionRefHouse = useRef(null);
    const listRefHouse = useRef(null);

    const titleRefAnnex = useRef(null);
    const descriptionRefAnnex = useRef(null);
    const listRefAnnex = useRef(null);

    useIntersectionObserver(
        [titleRefHouse, descriptionRefHouse, listRefHouse,
            titleRefAnnex, descriptionRefAnnex, listRefAnnex],
        ["animate-fade-in-right", "animate-fade-in-up", "animate-fade-in-up",
            "animate-fade-in-left", "animate-fade-in-up", "animate-fade-in-up"]
    );

    return (
        <div className="mt-12 lg:mt-40 relative">
            <div className="flex flex-col lg:flex-row items-end">
                <div className="w-10/12 mr-[16.666%] lg:w-1/2 lg:mr-0">
                    <CarouselImages imagesPaths={listOfPaths.section18thCentury} verticalAlign="left" horizontalAlign="top" />
                </div>
                <div className="lg:w-1/2 p-6 lg:pr-24 lg:pt-12 lg:pl-12 lg:pb-24">
                    <div ref={titleRefHouse} className="opacity-0 mb-6">
                        <H2 title={t('home.section18thCentury.title')} />
                    </div>
                    <div ref={descriptionRefHouse} className="opacity-0 mb-6">
                        <DescriptionSplit description={t('home.section18thCentury.description')} />
                    </div>

                    <div ref={listRefHouse} className="opacity-0">
                        <UnorderedList items={t('home.section18thCentury.list', { returnObjects: true })} />
                    </div>
                </div>
            </div>

            <div className="hidden lg:block absolute inset-0 w-fit h-fit mx-auto my-auto bg-white rounded-full p-2 z-10">
                <LogoIcon width="175" height="175" />
            </div>

            <div className="flex flex-col-reverse lg:flex-row items-start">
                <div className="lg:w-1/2 p-6 lg:pl-16 lg:pt-24 lg:pr-12">
                    <div ref={titleRefAnnex} className="opacity-0 mb-6">
                        <H2 title={t('home.sectionAnnexRestored.title')} />
                    </div>
                    <div ref={descriptionRefAnnex} className="opacity-0 mb-6">
                        <DescriptionSplit description={t('home.sectionAnnexRestored.description')} />
                    </div>

                    <div ref={listRefAnnex} className="opacity-0">
                        <UnorderedList items={t('home.sectionAnnexRestored.list', { returnObjects: true })} />
                    </div>
                </div>
                <div className="w-10/12 ml-[16.666%] lg:w-1/2 lg:ml-0">
                    <CarouselImages imagesPaths={listOfPaths.sectionAnnexRestored} verticalAlign="right" horizontalAlign="bottom" />
                </div>
            </div>
        </div>
    );
};

export default SectionMainHouseAnnex;
