import React from "react";
import { useTranslation } from "react-i18next";

import imgHouse from "../../../assets/images/domain/house.png";
import imgAnnex from "../../../assets/images/domain/annex.png";
import imgGarden from "../../../assets/images/domain/outside.png";

import Button from "../../../components/Buttons/button";

import FullWidthImage from "../../../components/Images/image-full-width";
import H2 from "../../../components/Titles/h2";
import H5 from "../../../components/Titles/h5";
import Link from "../../../components/Links/link";
import { Helmet } from "react-helmet";

export default function Domain() {
    const { t } = useTranslation();

    const listOfImages = [
        { src: imgHouse, alt: t('home.sectionDomainOverview.photos.alt1'), i18n: "house", link: "gaia" },
        { src: imgAnnex, alt: t('home.sectionDomainOverview.photos.alt3'), i18n: "annex", link: "hestia" },
        { src: imgGarden, alt: t('home.sectionDomainOverview.photos.alt2'), i18n: "outside", link: "outside" }
    ];

    return (
        <div>
            <Helmet>
                <title>{t('domain.page')}</title>
                <meta name="description" content={t('domain.description')} />
                <meta name="keywords" content={t('domain.keywords')} />
            </Helmet>

            <div className="flex flex-col md:flex-row gap-8 bg-secondary md:mt-0">
                {listOfImages.map((data, index) => (
                    <div key={index} className="relative w-screen md:w-1/2 md:h-screen">
                        <Link link={data.link} disableHoverEffect={true}>
                            <FullWidthImage url={data.src} alt={t(`domain.${data.i18n}.alt`)} className="h-[43vh] w-screen md:h-screen" />

                            <div className="absolute bottom-6 md:bottom-12 left-6 md:left-24">
                                <div className="bg-secondary p-1 md:p-2 w-fit">
                                    <H5 title={t(`domain.${data.i18n}.info`)} theme="white" />
                                </div>

                                <div className="bg-secondary p-2 md:p-4 mt-2 mb-4 md:mb-8 w-fit">
                                    <H2 title={t(`domain.${data.i18n}.title`)} theme="white" />
                                </div>

                                <Button text={t('inputs.discover')} />
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}