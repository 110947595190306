import { useState, useEffect, useRef } from 'react';
import KRGlue from '@lyracom/embedded-form-glue';
import { Systempay } from '../../services/systempay.service';
import Helper from '../../services/helper.service';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';

export default function SystempayComponent({ data, returnedData }) {
    const [message, setMessage] = useState('');
    const { t } = useTranslation();
    const isInitialRender = useRef(true);  // Track the first render

    function getLanguage(language) {
        switch (language) {
            case 'fr':
                return 'fr-FR';
            case 'en':
            default:
                return 'en-US';
        }
    }

    useEffect(() => {
        // Check if it's the first render
        if (isInitialRender.current) {
            isInitialRender.current = false;  // Set to false after first render
            async function setupPaymentForm() {
                const endpoint = 'https://api.systempay.fr/';

                const handleReturnedData = (status) => {
                    returnedData(status);
                };

                try {
                    const res = await Systempay.createPayment(data);

                    const formToken = res.data.formToken;
                    const publicKey = res.data.publicKey;

                    const { KR } = await KRGlue.loadLibrary(endpoint, publicKey);

                    // Remove any previously rendered forms to avoid the CLIENT_725 error
                    await KR.removeForms();

                    await KR.setFormConfig({
                        formToken: formToken,
                        'kr-language': getLanguage(data.language)
                    });

                    await KR.renderElements('#myPaymentForm');

                    // Listen for payment success
                    KR.onSubmit((response) => {
                        if (_.get(response, 'clientAnswer.orderStatus') === 'PAID') {
                            Helper.toast(t('responses.successPayment'), 'success');
                            handleReturnedData('paid');
                        }
                    });

                } catch (error) {
                    setMessage(error + ' (see console for more details)');
                    console.error('Payment form setup error:', error);
                }
            }

            setupPaymentForm();
        }
    }, [t, data, returnedData]);  // Track dependencies but ensure the effect only runs once


    return (
        <div className="form">
            <div className="container">
                <div id="myPaymentForm" className="flex justify-center">
                    <div className="kr-smart-form" kr-card-form-expanded="false"></div>
                </div>
                <div data-test="payment-message">{message}</div>
            </div>
        </div>
    );
}