import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PersonnalInfoForm from '../../../../components/Forms/personnal-info-form';
import BillingInfoFormQuotation from './billing-info-form';
import Hr from '../../../../components/Style/hr';
import Checkbox from '../../../../components/Inputs/checkbox';
import ButtonSecondary from '../../../../components/Buttons/button-secondary';
import Button from '../../../../components/Buttons/button';
import ExplanationStep from '../explanation-step';
import Textarea from '../../../../components/Inputs/textarea';
import Validator from '../../../../services/validator.service';
import Api from '../../../../services/api.service';
import Helper from '../../../../services/helper.service';

export default function AcceptQuotationForm({ quotation, returnedData }) {
    const [action, setAction] = useState();
    const [changeRequest, updateChangeRequestData] = useState("");
    const [checkbox1Checked, setCheckbox1Checked] = useState(false);
    const [checkbox2Checked, setCheckbox2Checked] = useState(false);
    const [checkboxError, setCheckboxError] = useState(false);
    const { t } = useTranslation();

    /* Personnal infos */
    const [personnalInfoFormData, setPersonnalInfoFormData] = useState({
        lastname: quotation.customer.lastname,
        firstname: quotation.customer.firstname,
        email: quotation.customer.email,
        phone: quotation.customer.phone
    });

    const updatePersonnalInfoFormData = (newData) => { setPersonnalInfoFormData(newData) };
    const [errorsPersonnalInfo, setErrorsPersonnalInfo] = useState({});

    /* Billing info */
    const [billingInfoFormData, setBillingInfoFormData] = useState({
        companyName: quotation.customer.companyName,
        siret: quotation.customer.siret,
        VATNumber: quotation.customer.VATNumber,
        country: quotation.address.country,
        city: quotation.address.city,
        address: quotation.address.address,
        postalCode: quotation.address.postalCode,
        address2: quotation.address.address2
    });

    const updateBillingInfoFormData = (newData) => { setBillingInfoFormData(newData) };
    const [errorsBillingInfo, setErrorsBillingInfo] = useState({});

    async function handleButtonActionClick(action) {
        if (action === "refused") {
            setAction(action);
            const response = await Api.changeStatusQuotation(quotation._id, action);
            Helper.handleApiResponse(response, t('responses.quotationRefused'), t('responses.quotationError'));
            returnedData(action);

        } else if (action === "modify") {
            setAction(action);

        } else if (action === "cancelModify") {
            setAction(null);

        } else if (action === "sendModifyRequest") {
            const response = await Api.sendChangeRequest(quotation._id, changeRequest);
            Helper.handleApiResponse(response, t('responses.quotationModified'), t('responses.quotationError'));

            returnedData("modified");

        } else if (action === "accept") {
            // Validate forms
            const tmpErrorsPersonnalInfo = Validator.validatePersonnalInfoFormData(personnalInfoFormData, t);
            const tmpErrorsBillingInfo = Validator.validateBillingInfoFormData(billingInfoFormData, t)

            setErrorsPersonnalInfo(tmpErrorsPersonnalInfo);
            setErrorsBillingInfo(tmpErrorsBillingInfo);

            // Check if there are any errors
            const isPersonalInfoValid = Object.keys(tmpErrorsPersonnalInfo).length === 0;
            const isBillingInfoValid = Object.keys(tmpErrorsBillingInfo).length === 0;


            if (!checkbox1Checked || !checkbox2Checked) {
                setCheckboxError(true);
                return;
            } else {
                setCheckboxError(false);
            }

            if (isPersonalInfoValid && isBillingInfoValid) {
                // The form data is valid
                quotation = Helper.setDataToQuotation(quotation, personnalInfoFormData, billingInfoFormData);
                quotation.mainInfos.status = "accepted";

                const response = await Api.saveQuotation(quotation);
                Helper.handleApiResponse(response, t('responses.quotationAccepted'), t('responses.quotationError'));

                const responseEmail = await Api.sendQuotation(quotation);
                Helper.handleApiResponse(responseEmail, t('responses.invoiceSent'), t('responses.invoiceError'));
                returnedData("step2");

            } else {
                console.log('Personal Info Errors:', tmpErrorsPersonnalInfo);
                console.log('Billing info errors :', tmpErrorsBillingInfo);
            }
        }
    };

    return (
        <div className='px-4 md:px-0'>
            <div className="flex flex-col gap-5 md:flex-row md:gap-40">
                <div className="w-full md:w-64 flex flex-col gap-5">
                    <ExplanationStep
                        title={t('pageQuotation.step1.personalAndBillingInformation')}
                        description={t('pageQuotation.step1.descriptionPersonalAndBillingInformation')} />
                </div>

                <div className="flex flex-col w-full">
                    <PersonnalInfoForm customer={personnalInfoFormData} errors={errorsPersonnalInfo} updatePersonnalInfoFormData={updatePersonnalInfoFormData} />

                    <Hr />

                    <BillingInfoFormQuotation data={billingInfoFormData} errors={errorsBillingInfo} updateBillingInfoFormData={updateBillingInfoFormData} />
                </div>
            </div>

            <Hr />

            <Checkbox label="checkbox1" onChange={setCheckbox1Checked} />
            <Checkbox label="checkbox2" onChange={setCheckbox2Checked} />
            {checkboxError && (
                <p className="mt-1 text-sm text-error ml-1">{t('errors.checkboxError')}</p>
            )}

            {!action && (
                <div className="flex flex-col-reverse md:flex-row md:justify-between gap-5 my-10 ">
                    <ButtonSecondary text={t('inputs.refuse')} action="refused" onClick={handleButtonActionClick} />
                    <ButtonSecondary text={t('inputs.modify')} action="modify" onClick={handleButtonActionClick} />
                    <Button text={t('inputs.accept')} action="accept" onClick={handleButtonActionClick} />
                </div>
            )}

            {action === "modify" && (
                <div className='mt-10'>
                    <Textarea
                        label={t('pageQuotation.step1.titleModifyQuotation')}
                        placeholder={t('pageQuotation.step1.placeholderModifyQuotation')}
                        value={changeRequest}
                        onChange={(e) => updateChangeRequestData(e.target.value)}
                    />

                    <div className="flex flex-col gap-5 mb-10 md:flex-row md:justify-between">
                        <ButtonSecondary text={t('inputs.cancel')} action="cancelModify" onClick={handleButtonActionClick} />
                        <Button text={t('inputs.sendModifyRequest')} action="sendModifyRequest" onClick={handleButtonActionClick} />
                    </div>
                </div>
            )}
        </div>
    )
};