import { useTranslation } from 'react-i18next';
import H2 from '../../../components/Titles/h2';
import DescriptionSplit from '../../../components/Description/description-split';
import Button from '../../../components/Buttons/button';
import Link from '../../../components/Links/link';

export default function ErrorStepQuotation() {
    const { t } = useTranslation();

    return (
        <div className='px-4 md:px-0'>
            <div className='text-center'>
                <H2 title={t('pageQuotation.error.title')} />

                <div className='mt-5'>
                    <DescriptionSplit description={t('pageQuotation.error.description')} />
                </div>

                <div className='mt-5'>
                    <Link link={"/"} disableHoverEffect={true}>
                        <Button text={t('pageQuotation.error.button')} />
                    </Link>
                </div>
            </div>
        </div>
    );
}