import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import DescriptionSplit from "../../../components/Description/description-split";
import CarouselImages from "../../../components/Carousel/carousel-images";
import ListOfEquipments from "./list-of-equipments";
import H1 from "../../../components/Titles/h1";
import H3 from "../../../components/Titles/h3";
import useIntersectionObserver from "../../../utils/hooks/intersection-observer";

export default function RoomDetails({ imagesPaths, carouselOn = "left", data }) {
    const { t } = useTranslation();
    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const equipmentsRef = useRef(null);

    useIntersectionObserver(
        [titleRef, descriptionRef, equipmentsRef],
        [
            `animate-fade-in-${carouselOn === "left" ? "right" : "left"}`,
            "animate-fade-in-up",
            "animate-fade-in-up"
        ]
    );

    return (
        <div className={`flex gap-6 md:gap-20 mb-8 md:mb-60 ${carouselOn !== "left" ? "flex-col md:flex-row-reverse" : "flex-col md:flex-row"}`}>
            <div className={`w-11/12 md:w-[48%] ${carouselOn === "left" ? "mr-[8.333%] md:mr-0" : "ml-[8.333%] md:ml-0"}`}>
                <CarouselImages imagesPaths={imagesPaths} verticalAlign={carouselOn} />
            </div>

            <div className={`w-full md:w-[52%] ${carouselOn !== "left" ? "p-4 md:pl-12" : "p-4 md:pr-12"}`}>
                <div ref={titleRef} className="opacity-0">
                    <span className="text-main">{data.location}</span>
                    <H1 title={data.title} />
                </div>

                <div ref={descriptionRef} className="opacity-0">
                    <DescriptionSplit description={data.description} className="mt-4 mb-12 md:text-sm" />
                </div>

                <div ref={equipmentsRef} className="opacity-0">
                    <H3 title={t('houseDetails.equipments')} />

                    <ListOfEquipments equipments={data.listOfEquipments} />
                </div>
            </div>
        </div>
    );
}