import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import imageHeaderBanner from "./../../assets/images/global/imageHeaderBanner.png";
import DescriptionSplit from "../../components/Description/description-split";
import Button from "../../components/Buttons/button";
import BackgroundImage from "../../components/Banner/background-header";
import H2 from "../../components/Titles/h2";
import H3 from "../../components/Titles/h3";
import PersonnalInfoForm from "../../components/Forms/personnal-info-form";
import Validator from "../../services/validator.service";
import Textarea from "../../components/Inputs/textarea";
import Api from "../../services/api.service";
import WaveComponent from "../../components/Style/wave";
import Helper from "../../services/helper.service";
import Icon from "../../components/Images/icon";
import iconMail from "../../assets/icons/contact/mail.svg";
import iconPhone from "../../assets/icons/contact/phone.svg";
import * as _ from "lodash";

export default function Contact() {
    const { t, i18n } = useTranslation();
    const [personnalInfoFormData, setPersonnalInfoFormData] = useState({ lastname: "", firstname: "", email: "", phone: "" });
    const updatePersonnalInfoFormData = (newData) => { setPersonnalInfoFormData(newData) };
    const [message, setMessage] = useState("");
    const handleMessageChange = (message) => { setMessage(message.target.value) };
    const [errorsPersonnalInfo, setErrorsPersonnalInfo] = useState({});
    const [errorMessage, setErrorsMessage] = useState({});

    async function handleSendButtonClick() {
        const tmpErrorsPersonnalInfo = Validator.validatePersonnalInfoFormData(personnalInfoFormData, t);
        setErrorsPersonnalInfo(tmpErrorsPersonnalInfo);
        const tmpErrorMessage = Validator.validateMessageContactFormData(message, t);
        setErrorsMessage(tmpErrorMessage);

        if (Object.keys(tmpErrorsPersonnalInfo).length === 0 && Object.keys(tmpErrorMessage).length === 0) {
            await Api.sendMessageContact({ ...personnalInfoFormData, message: message, language: i18n.language }).then((response) => {
                Helper.handleApiResponse(response, t('responses.messageContactSent'), t('responses.messageContactError'));
                if (_.get(response, 'data.status')) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'contactFormSubmissionSuccess',
                        formType: 'contact'
                    });
                }
            });
        }
    }

    return (
        <div>
            <Helmet>
                <title>{t('contact.page')}</title>
                <meta name="description" content={t('contact.description')} />
                <meta name="keywords" content={t('contact.keywords')} />
            </Helmet>
            <div className="absolute top-0 w-full">
                <BackgroundImage image={imageHeaderBanner} alt={t('reservation.altBackground')} />
            </div>
            <div className="relative m-auto my-32 bg-white p-4 md:p-8 rounded-3xl w-11/12 lg:w-3/4 shadow">
                <div className="hidden md:block absolute top-0 right-0">
                    <WaveComponent />
                </div>
                <div className="xl:w-1/2 md:w-2/3 pr-8">
                    <H2 title={t('contact.title')} className="text-2xl md:text-2xl lg:text-3xl" />
                    <DescriptionSplit description={t('contact.titleDescription')} className="mt-4 mb-6" />
                </div>
                <div className="flex flex-col md:flex-row gap-4 md:gap-12">
                    <div className="w-full xl:w-1/2 md:w-2/3">
                        <PersonnalInfoForm customer={personnalInfoFormData} errors={errorsPersonnalInfo} updatePersonnalInfoFormData={updatePersonnalInfoFormData} />
                        <div className="my-4 md:my-8">
                            <Textarea label={t('formInputs.message')} value={message} onChange={handleMessageChange} placeholder={t('formInputs.writeHere')} error={errorMessage} />
                        </div>
                        <div className="text-center md:text-left">
                            <Button text={t('inputs.send')} onClick={handleSendButtonClick} />
                        </div>
                    </div>
                    <div className="w-full xl:w-1/2 md:w-1/3">
                        <H3 title={t('contact.contactDetails')} />
                        <DescriptionSplit description={t('contact.contactDescription')} />
                        <div className="mt-8 flex flex-wrap justify-center gap-4">
                            <a href="tel:+33387880930" className="flex-1">
                                <div className="p-4 xl:p-8 rounded-3xl shadow-lg text-center w-fit m-auto flex flex-col items-center gap-4">
                                    <Icon icon={iconPhone} alt={t('contact.altIconPhone')} />
                                    <span className="font-bold text-md whitespace-nowrap">
                                        + 33 (0)3 87 88 09 30
                                    </span>
                                </div>
                            </a>
                            <a href="mailto:contact@lartbanne.com" className="flex-1">
                                <div className="p-4 xl:p-8 rounded-3xl shadow-lg text-center w-fit m-auto flex flex-col items-center gap-4">
                                    <Icon icon={iconMail} alt={t('contact.altIconMail')} />
                                    <span className="font-bold text-md whitespace-nowrap">
                                        contact@lartbanne.com
                                    </span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}