import React, { useRef } from "react";
import ServiceCard from "../service-card";
import H2 from "../../../../components/Titles/h2";
import DescriptionSplit from "../../../../components/Description/description-split";
import useIntersectionObserver from "../../../../utils/hooks/intersection-observer";
import Link from "../../../../components/Links/link";

export default function SectionPersonalizeChampagneExperience({ title, description, services }) {
    const serviceRefs = useRef(services.map(() => React.createRef()));
    useIntersectionObserver(serviceRefs.current, new Array(services.length).fill("animate-fade-in-up"), 250, 0.5);

    return (
        <div className="mt-12 md:mt-24 w-full">
            <div className="p-6 md:p-0 opacity-90">
                <div className="text-center p-4 md:p-12">
                    <H2 title={title} theme="dark" />
                </div>

                <div className="mb-6">
                    <DescriptionSplit description={description} text="center" className="max-w-5xl m-auto" />
                </div>

                <div className="w-11/12 lg:w-10/12 mx-auto flex flex-col lg:flex-row justify-between gap-12 lg:gap-16">
                    {services.map((service, index) => (
                        <div ref={serviceRefs.current[index]} key={index} className="opacity-0 initial-translate-up flex-1">
                            <Link link={service.link} disableHoverEffect={true}>
                                <ServiceCard title={service.title} description={service.description} path="sectionPersonalizeChampagneExperience" img={service.img} />
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
