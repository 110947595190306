import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL + "api/public/";
export const IMG_URL = process.env.REACT_APP_IMG_URL + "uploads/artbanne/";

export const Systempay = {
    createPayment: async function (data) {
        try {
            return await axios.post(API_URL + 'systempay/createPayment/', { data });
        } catch (error) {
            console.log(error);
            throw error; // Ensure the error is thrown so it can be caught in the component
        }
    },
};

export default Systempay;