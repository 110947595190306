import H2 from '../../../components/Titles/h2';
import DescriptionSplit from '../../../components/Description/description-split';

export default function TitleStatusQuotation({ title, description }) {
    return (
        <div className='text-center'>
            <H2 title={title} />

            <div className='mt-5'>
                <DescriptionSplit description={description} />
            </div>
        </div>
    )
};