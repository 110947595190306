import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Input({ label, name, type = "text", value, error, onChange, required = true }) {
    const { t } = useTranslation();

    // Correctly handle the required prop
    const isRequired = typeof required === 'string' ? required.toLowerCase() !== 'false' : Boolean(required);

    return (
        <div className="w-full">
            <label htmlFor={name} className="block mb-2 text-sm text-secondary">
                {t(`formInputs.${label}`)}
                {!isRequired && (
                    <span className="text-main text-xs ml-2">
                        {t("formInputs.optional")}
                    </span>
                )}
            </label>
            <input
                type={type}
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={t(`formInputs.${label}`)}
                required={isRequired}
                className={`w-full p-3 text-sm text-secondary bg-white border ${error ? "border-error" : "border-borderColor"} rounded-md`}
            />

            {error && (
                <p className="mt-1 text-xs text-error ml-1">{error}</p>
            )}
        </div>
    );
}
