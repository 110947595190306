import wave from "../../assets/images/style/wave.svg"
import { useTranslation } from "react-i18next";

export default function WaveComponent() {
    const { t } = useTranslation();

    return (
        <div className="relative overflow-hidden">
            <img src={wave} alt={t('style.altWave')} />
        </div>
    );
}
