// import { useTranslation, Trans } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Api from '../../../services/api.service';

import Loading from '../../../components/Loading/loading';
import LogoLong from '../../../components/Logos/logo-long';
import FirstStepAcceptQuotation from './first-step';
import SecondStepAcceptQuotation from './second-step';
import ThirdStepAcceptQuotation from './third-step';
import RefusedStepAcceptQuotation from './refused-step';
import ValidatedStepAcceptQuotation from './validated-step';
import Helper from '../../../services/helper.service';
import ModifiedStepAcceptQuotation from './modified-step';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ErrorStepQuotation from './error-step';
import PaidStepAcceptQuotation from './paid-step';
import * as _ from 'lodash';
import DepositPaidStepQuotation from './deposit-paid-step';

export default function QuotationPage() {
    const [loading, setLoading] = useState(true);
    const [quotation, setQuotation] = useState(null);
    const [step, setStep] = useState("step1");
    const { id } = useParams();

    const { t, i18n } = useTranslation();

    useEffect(() => {
        // Simulate an API request for quotation data
        Api.getQuotation(id).then((response) => {
            const res = response.data;

            if (res.status) {
                let quotationLoaded = res.data;

                let language = _.get(quotationLoaded, 'mainInfos.language', 'en');
                i18n.changeLanguage(language);

                setQuotation(quotationLoaded);
                setStep(Helper.getStepQuotation(quotationLoaded));

            } else {
                setStep("error")
                console.log("error");
            }

            setLoading(false);
        });
    }, [id, i18n, quotation?.mainInfos?.language]);

    const handleReturnedData = (data) => {
        setStep(data);
    };

    return (
        <div className='bg-backgroundColor'>
            <Helmet>
                <title>{t('pageQuotation.page')}</title>
            </Helmet>

            <header className="flex justify-center mt-8">
                <LogoLong width={250} height={64} />
            </header>

            <div className='m-auto max-w-5xl pt-14'>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        {step === "error" && (
                            <ErrorStepQuotation />
                        )}

                        {step === "step1" && (
                            <FirstStepAcceptQuotation quotation={quotation} returnedData={handleReturnedData} />
                        )}

                        {step === "step2" && (
                            <SecondStepAcceptQuotation quotation={quotation} returnedData={handleReturnedData} />
                        )}

                        {step === "step3" && (
                            <ThirdStepAcceptQuotation quotation={quotation} returnedData={handleReturnedData} />
                        )}

                        {step === "stepDepositPaid" && (
                            <DepositPaidStepQuotation quotation={quotation} returnedData={handleReturnedData} />
                        )}

                        {step === "refused" && (
                            <RefusedStepAcceptQuotation quotation={quotation} returnedData={handleReturnedData} />
                        )}

                        {step === "modified" && (
                            <ModifiedStepAcceptQuotation />
                        )}

                        {step === "validated" && (
                            <ValidatedStepAcceptQuotation />
                        )}

                        {step === "paid" && (
                            <PaidStepAcceptQuotation />
                        )}
                    </>
                )}
            </div>
        </div>
    );
}