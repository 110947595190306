import React, { useEffect, useState } from 'react';
import Input from '../../../../components/Inputs/input';
import DateFieldInput from '../../../../components/Inputs/date-field';
import lodash from "lodash";

export default function GuestInfoFormQuotation({ guest, index = 0, errors, updateGuestData }) {
    const [formData, setFormData] = useState({
        lastname: guest.lastname,
        firstname: guest.firstname,
        email: guest.email,
        birthdate: guest.birthdate,
        type: guest.type,
        index: index
    });
    const [errorsObject, setErrors] = useState({});

    useEffect(() => {
        if (errors) setErrors(errors);
        else setErrors({});

    }, [errors]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = { ...formData, [name]: value };
        setFormData(updatedFormData);
        updateGuestData(updatedFormData);
    };

    const handleDateChange = (e) => {
        const updatedFormData = { ...formData, [e.name]: lodash.get(e, "date.startDate", e.date) };
        setFormData(updatedFormData);
        updateGuestData(updatedFormData);
    };

    return (
        <div className="grid gap-8 grid-cols-1 md:grid-cols-2">
            <Input label="lastname" name="lastname" value={formData.lastname} error={errorsObject.lastname} onChange={handleInputChange} />
            <Input label="firstname" name="firstname" value={formData.firstname} error={errorsObject.firstname} onChange={handleInputChange} />
            {guest.type !== "child" &&
                <Input label="email" name="email" value={formData.email} error={errorsObject.email} onChange={handleInputChange} />
            }
            <DateFieldInput label="birthdate" name="birthdate" value={{ startDate: formData.birthdate, endDate: formData.birthdate }} displayLabel={true} error={errorsObject.birthdate} onChange={handleDateChange} />
        </div>
    )
};