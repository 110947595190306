import React from 'react';
import Header from './header';
import Footer from './footer';
import WidgetContact from '../../components/Widgets/widget-contact';

export default function Layout({ children }) {
    return (
        <div>
            <Header />
            <main>{children}</main>
            <WidgetContact />
            <Footer />
        </div>
    );
};
