import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import H4 from '../../../components/Titles/h4';
import { faEuroSign, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullWidthImage from '../../../components/Images/image-full-width';
import ButtonFit from '../../../components/Buttons/button-fit';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AccommodationDisplayed({ accommodation, accommodationSelected, prices, onChange }) {
    const [imageShadow, setImageShadow] = useState();
    const [images, setImages] = useState([]);
    const { t } = useTranslation();
    const [selected, setSelected] = useState(accommodationSelected);

    useEffect(() => {
        setSelected(accommodationSelected);
    }, [accommodationSelected]);

    // Load images
    useEffect(() => {
        const loadImages = async () => {
            const loadedImages = await Promise.all(accommodation.map(async (name) => {
                try {
                    const image = await import(`./../../../assets/images/reservation/${name}.png`);
                    return image.default;
                } catch (error) {
                    console.error('Error loading image:', error);
                    return null;
                }
            }));

            setImages(loadedImages.filter(Boolean)); // More concise way to filter out null values

            try {
                const image = await import(`./../../../assets/images/reservation/shadow.svg`);
                setImageShadow(image.default);
            } catch (error) {
                console.error('Error loading image:', error);
                return null;
            }
        };

        loadImages();
    }, [accommodation]);

    const handleSelectAccommodation = useCallback((item) => {
        let updatedSelection = [...selected];

        if (updatedSelection.includes(item)) {
            if (updatedSelection.length > 1) {
                updatedSelection = updatedSelection.filter(name => name !== item); // Remove item from selection
            } else {
                toast.error(t('reservation.accommodation.error'), {
                    autoClose: 3000,
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }
        } else {
            updatedSelection.push(item);
        }

        setSelected(updatedSelection);
        onChange(updatedSelection);

    }, [selected, onChange, t]);

    return (
        <>
            {accommodation.map((item, index) => (
                <div key={item} className={`relative w-auto h-80 relative ${selected.includes(item) ? "bg-main border-main border-4 rounded-xl" : "bg-white border-white border-4 rounded-xl"}`}>
                    <FullWidthImage url={images[index]} alt={t(`reservation.accommodation.${item}.alt`)} className="rounded-xl" customHeight="100%" />
                    <FullWidthImage url={imageShadow} alt={t(`reservation.accommodation.shadow`)} divClassName="absolute top-0 h-full w-full" className="rounded-xl" customHeight="100%" />

                    <div className='w-full absolute left-0 bottom-0 p-4'>
                        <H4 title={t(`reservation.accommodation.${item}.title`)} theme='white' />
                        <div className='flex justify-between items-center'>
                            <div className='flex flex-col'>
                                <div className='flex items-center'>
                                    <FontAwesomeIcon icon={faUser} className='text-main' />
                                    <span className='text-sm text-white ml-2'>{t(`reservation.accommodation.${item}.nbPersons`)}</span>
                                </div>
                                <div className='flex items-center'>
                                    <FontAwesomeIcon icon={faEuroSign} className='text-main' />
                                    <span className='text-sm text-white ml-2'>{prices[item].price} {t(`reservation.accommodation.${item}.price`)}</span>
                                </div>
                            </div>

                            {(selected.length > 1 || !selected.includes(item)) &&
                                <ButtonFit
                                    text={selected.includes(item) ? t(`inputs.remove`) : t(`inputs.add`)}
                                    onClick={() => handleSelectAccommodation(item)}
                                />}
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}
