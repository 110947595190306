import fr from "../../assets/images/flags/fr.png"
import de from "../../assets/images/flags/de.png"
import en from "../../assets/images/flags/en.png"

export default function FlagLanguage({ language }) {
    const getFlag = () => {
        switch (language) {
            case 'fr':
                return fr;
            case 'de':
                return de;
            case 'en':
            default:
                return en;
        }
    }

    return (
        <div>
            <img src={getFlag()} alt={language} className="h-3 m-1.5" />
        </div>

    )
};