import React, { useRef } from "react";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import H3 from "../../../components/Titles/h3";
import DescriptionSplit from "../../../components/Description/description-split";
import useIntersectionObserver from "../../../utils/hooks/intersection-observer";

export default function FAQ({ data }) {
    const faqRefs = useRef(data.map(() => React.createRef()));
    useIntersectionObserver(faqRefs.current, new Array(data.length).fill("animate-fade-in-up"), 100, 0.25);

    return (
        <div className="grid md:grid-cols-2 gap-4 md:gap-12 mt-4">
            {data.map((d, index) => (
                <div className="mt-2 md:mt-6 opacity-0" ref={faqRefs.current[index]} key={"question " + index}>
                    <div className="flex items-center gap-2">
                        <div className="text-white bg-main w-8 h-8 rounded-full flex items-center justify-center">
                            <FontAwesomeIcon icon={faQuestion} className="text-2xl" />
                        </div>
                        <div style={{ width: "calc(100% - 32px - 0.5rem)" }}>
                            <H3 title={d.question} />
                        </div>
                    </div>
                    <div className="mt-4">
                        <DescriptionSplit description={d.answer} />
                    </div>
                </div>
            ))}
        </div>
    );
}