import { useState } from 'react';
import Hr from '../../../components/Style/hr';
import PaiementMethodFormQuotation from './Forms/paiement-method-form';
import DetailPaiementQuotation from './detail-paiement-quotation';

export default function PaiementQuotation({ quotation, returnedData }) {
    const [selectedCard, setSelectedCard] = useState(null);

    const handleCardClicked = (cardName) => {
        setSelectedCard(cardName);
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth'
        });
    };

    const handleReturnedData = (data) => {
        returnedData(data);
    };

    return (
        <>
            <PaiementMethodFormQuotation quotation={quotation} onCardClicked={handleCardClicked} />
            <Hr />

            {selectedCard &&
                <DetailPaiementQuotation selectedCard={selectedCard} quotation={quotation} returnedData={handleReturnedData} />
            }
        </>
    )
};