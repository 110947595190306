const Validator = {
  validatePersonnalInfoFormData(formData, t) {
    const errors = {};

    if (!formData.lastname) {
      errors.lastname = t('errors.noLastname');
    }

    if (!formData.firstname) {
      errors.firstname = t('errors.noFirstname');
    }

    if (!formData.email) {
      errors.email = t('errors.noEmail');
    } else if (!Validator.isValidEmail(formData.email)) {
      errors.email = t('errors.wrongEmail');
    }

    if (!formData.phone) {
      errors.phone = t('errors.noPhone');
    }

    return errors;
  },

  validateEventInfoFormData(formData, t) {
    const errors = {};

    if (!formData.companyName) {
      errors.companyName = t('errors.noCompanyName');
    }

    if (!formData.event) {
      errors.event = t('errors.noEvent');
    }

    if (!formData.nbPersons) {
      errors.nbPersons = t('errors.noNbPersons');
    }

    if (!formData.stayDates) {
      errors.date = t('errors.noDate');
    }

    return errors;
  },

  validateMessageContactFormData(message, t) {
    if (message.length < 4) {
      return { message: t('errors.tooShortMessage') };

    } else if (message.length > 9999) {
      return { message: t('errors.tooLongMessage') };

    } else {
      return {};
    }
  },

  isValidEmail(email) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  },

  validateBillingInfoFormData(formData, t) {
    const errors = {};

    if (!formData.country) {
      errors.country = t('errors.noCountry');
    }

    if (!formData.city) {
      errors.city = t('errors.noCity');
    }

    if (!formData.address) {
      errors.address = t('errors.noAddress');
    }

    if (!formData.postalCode) {
      errors.postalCode = t('errors.noPostalCode');
    }

    return errors;
  },

  thePersonIsOver18YearsOld(date) {
    // Get the current date
    const currentDate = new Date();

    // Calculate a date that is 18 years ago
    const eighteenYearsAgo = new Date(currentDate);
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    // Compare the given date with the calculated date
    return new Date(date) < eighteenYearsAgo;
  },

  validateGuests(arrayOfGuests, type, t) {
    const errors = [];
    const isAnAdult = type === "customer" || type === "adult";

    for (const guest of arrayOfGuests) {
      let errorObject = {};

      if (type === "customer" && !guest.lastname) {
        errorObject.lastname = t('errors.noLastname');
      }

      if (type === "customer" && !guest.firstname) {
        errorObject.firstname = t('errors.noFirstname');
      }

      if (isAnAdult) {
        if (type === "customer" && !guest.email) {
          errorObject.email = t('errors.noEmail');

        } else if (type === "customer" && !Validator.isValidEmail(guest.email)) {
          errorObject.email = t('errors.wrongEmail');
        }
      }

      if (type === "customer" && !guest.birthdate) {
        errorObject.birthdate = t('errors.noBirthdate');

      } else if (type === "customer" && (isAnAdult && !Validator.thePersonIsOver18YearsOld(guest.birthdate))) {
        errorObject.birthdate = t('errors.wrongBirthdateAdult');

      } else if (type === "customer" && (!isAnAdult && Validator.thePersonIsOver18YearsOld(guest.birthdate))) {
        errorObject.birthdate = t('errors.wrongBirthdateChild');
      }

      // Check if errorObject is not empty and push it to errors
      if (Object.keys(errorObject).length > 0) {
        errors.push(errorObject);
      }
    }

    return errors;
  }
};

export default Validator;