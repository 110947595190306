import { useTranslation } from 'react-i18next';

import TitleStatusQuotation from './../components/title-status-quotation';
import Hr from '../../../components/Style/hr';
import Button from '../../../components/Buttons/button';
import Api from '../../../services/api.service';
import Helper from '../../../services/helper.service';

export default function RefusedStepAcceptQuotation({ quotation, returnedData }) {
    const { t } = useTranslation();

    async function changeStatusQuotationToDraft() {
        const response = await Api.changeStatusQuotation(quotation._id, "draft");
        Helper.handleApiResponse(response, t('responses.quotationBackToDraft'), t('responses.quotationError'));
        returnedData("step1");
    }

    return (
        <div className='px-4 md:px-0'>
            <TitleStatusQuotation title={t('pageQuotation.refused.title')} description={t('pageQuotation.refused.description')} />
            <Hr />

            <div className='text-center'>
                <Button text={t('pageQuotation.refused.button')} onClick={changeStatusQuotationToDraft} />
            </div>
        </div>
    );
}