import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import {
    frQuotationPage, enQuotationPage,
    frFormInputs, enFormInputs,
    frInputs, enInputs,
    frErrors, enErrors,
    frResponses, enResponses,
    frHeader, enHeader,
    frFooter, enFooter,
    frHome, enHome,
    frReservation, enReservation,
    frDomain, enDomain,
    frHouseDetails, enHouseDetails,
    frAnnexDetails, enAnnexDetails,
    frGardenDetails, enGardenDetails,
    frEquipments, enEquipments,
    frServices, enServices,
    frInfos, enInfos,
    frHistory, enHistory,
    frEvents, enEvents,
    frContact, enContact,
    frWidgetContact, enWidgetContact,
    frStyle, enStyle,
    frActivities, enActivities,
    frErrorPage, enErrorPage
} from "./utils/imports/import-translations";

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: ["fr", "en-US", "de"],
        lng: 'fr',
        interpolation: {
            escapeValue: false
        },
        resources: {
            fr: {
                translation: {
                    pageQuotation: frQuotationPage,
                    formInputs: frFormInputs,
                    inputs: frInputs,
                    errors: frErrors,
                    responses: frResponses,
                    header: frHeader,
                    footer: frFooter,
                    home: frHome,
                    reservation: frReservation,
                    domain: frDomain,
                    houseDetails: frHouseDetails,
                    annexDetails: frAnnexDetails,
                    gardenDetails: frGardenDetails,
                    equipments: frEquipments,
                    activities: frActivities,
                    services: frServices,
                    infos: frInfos,
                    history: frHistory,
                    events: frEvents,
                    contact: frContact,
                    widgetContact: frWidgetContact,
                    style: frStyle,
                    errorPage: frErrorPage
                }
            },
            en: {
                translation: {
                    pageQuotation: enQuotationPage,
                    formInputs: enFormInputs,
                    inputs: enInputs,
                    errors: enErrors,
                    responses: enResponses,
                    header: enHeader,
                    footer: enFooter,
                    home: enHome,
                    reservation: enReservation,
                    domain: enDomain,
                    houseDetails: enHouseDetails,
                    annexDetails: enAnnexDetails,
                    gardenDetails: enGardenDetails,
                    equipments: enEquipments,
                    activities: enActivities,
                    services: enServices,
                    infos: enInfos,
                    history: enHistory,
                    events: enEvents,
                    contact: enContact,
                    widgetContact: enWidgetContact,
                    style: enStyle,
                    errorPage: enErrorPage
                }
                // },
                // de: {
                //     translation: {
                //         pageQuotation: deQuotationPage,
                //     }
            }
        }
    });

export default i18n;