import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import H2 from "../../../../components/Titles/h2";
import H3 from "../../../../components/Titles/h3";
import DescriptionSplit from "../../../../components/Description/description-split";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faQuoteRight, faStar, faStarHalf } from "@fortawesome/free-solid-svg-icons";
import "./carousel-testimonials.scss";

export default function SectionTestimonials({ title, data }) {
    const [displayedData] = useState(data);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isHovering, setIsHovering] = useState(false);

    useEffect(() => {
        let interval;
        if (!isHovering) {
            interval = setInterval(() => {
                setCurrentIndex((currentIndex + 1) % displayedData.length);
            }, 10000);
        }

        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [currentIndex, data.length, isHovering]);

    const handleNext = () => {
        if ((currentIndex + getDataSlidesToShow().slidesToShow) >= displayedData.length) {
            setCurrentIndex(0);
        } else {
            setCurrentIndex(currentIndex + getDataSlidesToShow().slidesToShow)
        }
    };

    const handlePrev = () => {
        setCurrentIndex((currentIndex - getDataSlidesToShow().slidesToShow + displayedData.length) % displayedData.length);
    };

    const getDataSlidesToShow = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth < 768) {
            return { slidesToShow: 1, centerSlidePercentage: 100 };

        } else if (screenWidth < 1024) {
            return { slidesToShow: 2, centerSlidePercentage: 50 };

        } else {
            return { slidesToShow: 3, centerSlidePercentage: 33.33 };
        }
    };

    return (
        <div className="mt-12 md:mt-24 w-full bg-testimonials p-12 md:p-20 overflow-hidden">
            <div className="w-full relative" onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
                <div className="text-center mb-6 md:mb-14">
                    <H2 title={title} />
                </div>

                <div className="relative">
                    <Carousel
                        showThumbs={false}
                        showIndicators={false}
                        showStatus={false}
                        selectedItem={currentIndex}
                        onChange={(index) => setCurrentIndex(index)}
                        centerMode={true}
                        centerSlidePercentage={getDataSlidesToShow().centerSlidePercentage}
                        slidesToShow={getDataSlidesToShow().slidesToShow}
                    // infiniteLoop={true}
                    >
                        {displayedData.map((testimonial, i) => (
                            <div key={i} className="flex justify-center p-4 h-full">
                                <div className="bg-white rounded-xl py-3 px-8 relative max-w-md flex flex-col justify-between">
                                    <FontAwesomeIcon icon={faQuoteRight} className="absolute -top-4 -right-4 text-main text-4xl" />
                                    <DescriptionSplit description={testimonial.description} />
                                    <div>
                                        <div className="flex justify-center gap-2 my-5">
                                            {Array.from({ length: Math.floor(testimonial.stars) }, (_, index) => (
                                                <FontAwesomeIcon key={index} icon={faStar} className="text-main" />
                                            ))}
                                            {testimonial.stars % 1 !== 0 && <FontAwesomeIcon icon={faStarHalf} className="text-main" />}
                                        </div>
                                        <H3 title={testimonial.author} className="text-center mt-6" />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>

                    <div className="absolute -left-10 top-1/2 transform -translate-y-1/2 z-10">
                        <button onClick={handlePrev} className="bg-gray-100 text-gray-500 py-4 px-6 rounded-full">
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                    </div>
                    <div className="absolute -right-10 top-1/2 transform -translate-y-1/2 z-10">
                        <button onClick={handleNext} className="bg-main text-white py-4 px-6 rounded-full">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}