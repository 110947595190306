import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Banner1 from "./../../../../assets/images/home/banner-home.jpg"
import Banner2 from "./../../../../assets/images/home/sectionDomainOverview/section1-1.jpg";
import Banner3 from "./../../../../assets/images/home/sectionDomainOverview/section1-2.jpg";
import Banner4 from "./../../../../assets/images/home/sectionDomainOverview/section1-3.jpg";

// import BackgroundImage from '../../../../components/Banner/background-header';
import DateFieldInput from '../../../../components/Inputs/date-field';
import Select from '../../../../components/Inputs/select';
import Button from '../../../../components/Buttons/button';
import { faUser } from "@fortawesome/free-solid-svg-icons";
import Api from '../../../../services/api.service';
import { useNavigate } from 'react-router-dom';


import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import './swiper-custom.css';

import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper/modules';

const HeaderBanner = ({ stayDates, handleDateChange, nbPersons, handleSelectChangePersons }) => { // banner
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [blockedDates, setBlockedDates] = useState([]);
    const [retryCount, setRetryCount] = useState(0);

    // Directly set the images using imported assets
    const [images] = useState([
        { src: Banner1, alt: 'Banner Home' },
        { src: Banner2, alt: 'Section Domain Overview 1' },
        { src: Banner3, alt: 'Section Domain Overview 2' },
        { src: Banner4, alt: 'Section Domain Overview 3' }
    ]);


    useEffect(() => {
        const fetchBlockedDates = () => {
            if (Api.isDataLoaded()) {
                const dates = Api.getBlockedDatesStored();
                setBlockedDates(dates);

            } else if (retryCount < 20) {
                setTimeout(fetchBlockedDates, 500);
                setRetryCount(retryCount + 1);

            } else {
                console.log("Failed to load data after 5 attempts.");
            }
        };

        fetchBlockedDates();
        return () => clearTimeout(fetchBlockedDates);

    }, [retryCount]);

    const nbPersonsOptions = () => {
        return Array.from({ length: 23 }, (_, index) => {
            if (index === 0) {
                return null; // Exclude the option with value 0
            }

            return {
                value: `${index}`,
                label: `${index} ${index > 1 ? `${t('formInputs.persons')}` : `${t('formInputs.person')}`}`,
            };
        }).filter(option => option !== null);
    };

    function navigateReservation() {
        Api.saveStayDatas({ nbPersons: nbPersons, stayDates: stayDates });
        navigate('/reservation');
    }

    const twoYearsLater = new Date(new Date().setFullYear(new Date().getFullYear() + 2));

    return (
        <div className="relative">
            {/* <BackgroundImage image={banner} alt={t('home.sectionBanner.altBackground')} displayGradient={false} /> */}

            <style>
                {`
                .swiper-button-next,
                .swiper-button-prev {
                    @apply text-main;
                }

                .swiper-button-next::after,
                .swiper-button-prev::after {
                    @apply text-2xl; /* Tailwind font size */
                }

                .swiper-pagination-bullet {
                    @apply bg-main; /* Tailwind background color */
                }
                `}
            </style>

            <Swiper
                modules={[Autoplay, Pagination, EffectFade, Navigation]}
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                effect={'fade'}
                autoplay={{ delay: 5000 }}
                navigation={true}
                pagination={{
                    dynamicBullets: true,
                    clickable: true
                }}
                style={{ width: 'calc(100vw - 7px)', height: '100vh' }}
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img src={image.src} alt={image.alt} className="w-full h-full object-cover" />
                    </SwiperSlide>
                ))}
            </Swiper>


            <div className="absolute bottom-8 lg:bottom-24 left-1/2 transform -translate-x-1/2 w-10/12 lg:w-3/4 bg-white bg-opacity-80 rounded-xl flex flex-col items-center lg:flex-row justify-between lg:items-end gap-4 lg:gap-10 p-4 lg:p-6 z-50">
                <div className="w-full lg:flex-1 min-w-[250px]">
                    <DateFieldInput label='stayDate' value={stayDates} minDate={new Date()} maxDate={twoYearsLater} onChange={handleDateChange} displayLabel={true} singleDate={false} blockedDates={blockedDates} />
                </div>
                <div className="w-full lg:flex-1">
                    <Select label="nbPersons" name={t('formInputs.nbPersons')} options={nbPersonsOptions()} value={nbPersons} onChange={handleSelectChangePersons} displayLabel={true} iconDisplayed={faUser} error={null} />
                </div>
                <div className="w-full lg:flex-0 contents">
                    <Button text={t('home.sectionBanner.btn')} onClick={navigateReservation} className='mb-[2px]' />
                </div>
            </div>
        </div>
    );
};

export default HeaderBanner;
