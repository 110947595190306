import { useEffect, useRef } from 'react';

const CGV = () => {
    const hasOpenedPDF = useRef(false);

    useEffect(() => {
        if (!hasOpenedPDF.current) {
            const openPDF = async () => {
                const fileURL = `https://lartbanne.com/assets/CGV.pdf`;

                try {
                    const newWindow = window.open(fileURL, '_blank');

                    if (newWindow) {
                        await new Promise((resolve) => {
                            newWindow.onload = resolve;
                        });
                    } else {
                        console.error('Failed to open PDF in new window.');
                    }
                } catch (error) {
                    console.error('Error opening PDF:', error);
                }
            };

            openPDF();
            hasOpenedPDF.current = true;
            window.location.href = '/';
        }
    }, []);
}

export default CGV;
