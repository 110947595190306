import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export default function Select({ label, name, options, value, error, onChange, displayLabel = true, required = true, iconDisplayed = "", className = "" }) {
    const { t } = useTranslation();

    // Correctly handle the required prop
    const isRequired = typeof required === 'string' ? required.toLowerCase() !== 'false' : Boolean(required);

    return (
        <div className="w-full">
            {displayLabel && (
                <label htmlFor={name} className="block mb-2 text-sm text-secondary">
                    {t(`formInputs.${label}`)}
                    {!isRequired && (
                        <span className="ml-2 text-xs text-main">
                            {t("formInputs.optional")}
                        </span>
                    )}
                </label>
            )}

            <div className="relative">
                {iconDisplayed && (
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <FontAwesomeIcon icon={iconDisplayed} className="text-xl text-main" />
                    </div>
                )}

                <select
                    id={name}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required={isRequired}
                    className={`w-full p-3 text-sm text-secondary bg-white border ${error ? "border-error" : "border-borderColor"} rounded-md ${iconDisplayed ? "pl-10" : "pl-3"} ${className} cursor-pointer`}>
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>

            {error && (
                <p className="mt-1 text-xs text-error ml-1">{error}</p>
            )}
        </div>
    );
}
