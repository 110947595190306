import React, { useEffect, useState } from "react";
import IconEquipment from "./icon-equipment";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export default function ListOfEquipments({ equipments }) {
    const [icons, setIcons] = useState([]);
    const { t } = useTranslation();
    const [showAll, setShowAll] = useState(false);
    const [maxEquipments, setMaxEquipments] = useState(8); // Maximum number of icons to show initially

    const visibleEquipments = showAll ? equipments : equipments.slice(0, maxEquipments);

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    useEffect(() => {
        const loadImages = async () => {
            const loadedIcons = await Promise.all(
                equipments.map(async (equipment) => {
                    try {
                        const icon = await import(`./../../../assets/icons/equipments/${equipment}.svg`);
                        return icon.default;
                    } catch (error) {
                        console.error('Error loading image:', error);
                        return null;
                    }
                })
            );
            setIcons(loadedIcons.filter((icon) => icon !== null));
        };

        loadImages();
    }, [equipments]);

    useEffect(() => {
        const updatemaxEquipments = () => {
            const screenWidth = window.innerWidth;

            if (screenWidth < 576) {
                setMaxEquipments(0);

            } else if (screenWidth < 768) {
                setMaxEquipments(2);

            } else if (screenWidth < 1024) {
                setMaxEquipments(4);

            } else if (screenWidth < 1260) {
                setMaxEquipments(6);

            } else {
                setMaxEquipments(8);
            }
        };

        updatemaxEquipments();
        window.addEventListener("resize", updatemaxEquipments);

        return () => window.removeEventListener("resize", updatemaxEquipments);
    }, []);

    return (
        <div>
            <div className="grid grid-cols-2 gap-4 mt-2 md:mt-4">
                {visibleEquipments.map((equipment, index) => (
                    <div className="flex items-center gap-2" key={"equipment" + index}>
                        <IconEquipment icon={icons[index]} alt={t(`equipments.alt`) + " " + t(`equipments.${equipment}`)} />
                        <span className="text-sm">{t(`equipments.${equipment}`)}</span>
                    </div>
                ))}
            </div>
            <div className="mt-6 w-full">
                {!showAll && equipments.length > maxEquipments && (
                    <button className="text-sm" onClick={toggleShowAll}>
                        <FontAwesomeIcon icon={faChevronDown} className="text-xl mr-2" />
                        {t('equipments.seeMore')}
                    </button>
                )}
            </div>
        </div>
    );
}