import React, { useState, useEffect, useRef } from 'react';
import Button from '../../components/Buttons/button';
import Link from '../../components/Links/link';
import LogoLong from '../../components/Logos/logo-long';
import I18nComponentChooseLanguage from '../../components/Widgets/i18n-choose-language';
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

export default function Header() {
    const { t } = useTranslation();
    // const navigate = useNavigate();
    const headerRef = useRef(null);

    const [scrolling, setScrolling] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            setScrolling(scrollTop > 200);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // function clickReservation() {
    //     navigate('/reservation');
    // }

    // function clickContact() {
    //     navigate('/contact');
    // }

    return (
        <header ref={headerRef} style={{ zIndex: 1000 }} className={`w-full fixed top-0 transition ${scrolling || isMenuOpen ? 'bg-white shadow-md' : 'bg-gradient-to-b from-secondary to-transparent'} py-2 lg:py-4`}>
            <div className={`container mx-auto flex items-center justify-between px-8 sm:px-12 lg:pr-0 max-w-full gap-12`}>
                <div className="h-fit flex">
                    <Link link="/" disableHoverEffect={true}>
                        <LogoLong width={360} height={60} color={`${scrolling || isMenuOpen ? "dark" : "white"}`} />
                    </Link>
                </div>

                <div className={`flex flex-col lg:flex-row lg:flex gap-4 lg:gap-10 lg:justify-evenly items-center absolute lg:static bg-white lg:bg-transparent w-full left-0 px-4 py-2 lg:p-0 transition-transform ${isMenuOpen ? 'top-14' : 'top-[-490px]'} text-center`}>
                    {isMenuOpen && <div className='flex lg:hidden'>
                        <I18nComponentChooseLanguage />
                    </div>
                    }
                    <Link link="/domain" text={t('header.domain')} textColor={scrolling || isMenuOpen ? 'secondary' : 'white'} />
                    <Link link="/services" text={t('header.services')} textColor={scrolling || isMenuOpen ? 'secondary' : 'white'} />
                    <Link link="/events" text={t('header.events')} textColor={scrolling || isMenuOpen ? 'secondary' : 'white'} />
                    <Link link="/infos" text={t('header.infos')} textColor={scrolling || isMenuOpen ? 'secondary' : 'white'} />
                </div>

                <div className='flex gap-4'>
                    {/* <Button text={t('header.reservation')} onClick={clickReservation} /> */}
                    <Link link="/reservation" disableHoverEffect={true}>
                        <Button text={t('header.reservation')} />
                    </Link>


                    <button className="lg:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <FontAwesomeIcon icon={faBars} className={scrolling || isMenuOpen ? 'text-dark' : 'text-white'} />
                    </button>

                    <div className='hidden w-24 lg:flex items-center'>
                        <I18nComponentChooseLanguage />
                    </div>
                </div>
            </div>
        </header>
    );
}
