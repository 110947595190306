export default function ButtonFit({ text, action, onClick, className = "" }) {
    const handleClick = () => {
        if (onClick) {
            onClick(action);
        }
    };

    return (
        <button onClick={handleClick} className={`bg-main text-white text-sm py-2 px-4 rounded-xl transition-all duration-300 ease-in-out hover:bg-main-dark ${className}`}>
            {text}
        </button>
    );
}