import React from 'react';

export default function FullWidthImage({ url, alt, customHeight, customWidth, divClassName = "relative h-full", className = "" }) {
    const imgStyle = {};

    if (customHeight) {
        imgStyle.height = customHeight;
        imgStyle.maxHeight = customHeight;
    }
    if (customWidth) {
        imgStyle.width = customWidth;
        imgStyle.maxWidth = customWidth;
    }

    return (
        <div className={`${divClassName}`}>
            <img
                src={url}
                alt={alt}
                style={imgStyle}
                className={`object-cover object-center w-full h-full max-w-full max-h-full ${className}`}
            />
        </div>
    );
}
