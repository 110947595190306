import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown, faFileLines } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../components/Buttons/button';
import Api from '../../../services/api.service';


export default function DownloadQuotation({ quotation }) {
    const { t } = useTranslation();

    async function clickVisualizeQuotation() {
        await Api.visualizeQuotation(quotation);
    }

    async function clickDownloadQuotation() {
        await Api.downloadQuotation(quotation);
    }

    return (
        <div className='px-4 md:px-0'>
            <h2 className='font-bold text-lg text-secondary'>{t('pageQuotation.step1.titleYourQuotation')}</h2>

            <div className='flex flex-col mt-5 md:flex-row md:justify-between'>
                <div className='flex items-center mb-3 md:mb-0 justify-between md:justify-start'>
                    <div>
                        <FontAwesomeIcon icon={faFileLines} className='h-6 text-main mr-3 md:mr-5' />
                        <span>
                            {t('pageQuotation.step1.nameQuotation')} {quotation.mainInfos.quotationNumber}
                        </span>
                    </div>

                    <div className='flex md:hidden'>
                        <FontAwesomeIcon icon={faCloudArrowDown} className='h-6 text-main cursor-pointer' onClick={clickDownloadQuotation} />
                    </div>
                </div>
                <div className='flex items-center gap-3 md:gap-5'>
                    <Button text={t('pageQuotation.step1.buttonLabelVisualizeQuotation')} onClick={clickVisualizeQuotation} className='w-full md:w-fit' />

                    <div className='hidden md:flex'>
                        <FontAwesomeIcon icon={faCloudArrowDown} className='h-6 text-main cursor-pointer' onClick={clickDownloadQuotation} />
                    </div>
                </div>
            </div>
        </div>
    )
};