import React from 'react';

export default function Link({ link, text, textColor = "black", externalLink = false, disableHoverEffect = false, children = <></> }) {
    const target = externalLink ? '_blank' : '_self';
    const relAttribute = externalLink ? 'noopener noreferrer' : '';

    const hoverClasses = disableHoverEffect ? '' : 'group-hover:scale-x-100';

    return (
        <a href={link} target={target} rel={relAttribute} className={`group relative inline-block text-${textColor} no-underline hover:text-${textColor}`}>
            {children}
            <span dangerouslySetInnerHTML={{ __html: text }} />
            <span className={`absolute bottom-0 left-0 w-full h-[1px] bg-${textColor} scale-x-0 ${hoverClasses} transform transition-transform ease-out duration-300 origin-left`}></span>
        </a>
    );
}
