import React from 'react';
import LogoIcon from '../Logos/logo-icon';

function Loading() {
    return (
        <div className="relative flex justify-center items-center">
            <div className="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-main"></div>
            <LogoIcon />
        </div>
    );
}

export default Loading;