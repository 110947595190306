import React from 'react';
import Input from '../Inputs/input';

export default function PersonnalInfoForm({ customer, errors, updatePersonnalInfoFormData, fullWidth = false }) {
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updatePersonnalInfoFormData({ ...customer, [name]: value });
    };

    return (
        <div className={`grid gap-6 md:gap-8 ${fullWidth ? 'grid-cols-1' : 'grid-cols-1 md:grid-cols-2'}`}>
            <Input label="lastname" name="lastname" value={customer.lastname} error={errors.lastname} onChange={handleInputChange} />
            <Input label="firstname" name="firstname" value={customer.firstname} error={errors.firstname} onChange={handleInputChange} />
            <Input label="email" name="email" value={customer.email} error={errors.email} onChange={handleInputChange} />
            <Input label="phone" name="phone" value={customer.phone} error={errors.phone} onChange={handleInputChange} />
        </div>
    );
}
