import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import ServiceCard from "../service-card";
import Button from "../../../../components/Buttons/button";
import H2 from "../../../../components/Titles/h2";
import Link from "../../../../components/Links/link";
import useIntersectionObserver from "../../../../utils/hooks/intersection-observer";

export default function SectionServices({ title, services, button, link = "services" }) {
    const serviceRefs = useRef(services.map(() => React.createRef()));
    useIntersectionObserver(serviceRefs.current, new Array(services.length).fill("animate-fade-in-up"), 250, 0.5);

    const navigate = useNavigate();

    function clickButton() {
        navigate(link);
    }

    return (
        <div className="mt-12 md:mt-24 w-full">
            <div className="p-6 md:p-0 bg-secondary opacity-90">
                <div className="text-center p-4 md:p-12">
                    <H2 title={title} theme="white" />
                </div>

                <div className="w-11/12 md:w-10/12 mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 md:gap-8 lg:gap-12">
                    {services.map((service, index) => (
                        <div ref={serviceRefs.current[index]} key={index} className="opacity-0 initial-translate-up flex-1">
                            <ServiceCard title={service.title} description={service.description} path="sectionServices" img={service.img} />
                        </div>
                    ))}
                </div>


                <div className="text-center pt-8 pb-4 md:py-12">
                    <Link link={link} disableHoverEffect={true}>
                        <Button text={button} onClick={clickButton} />
                    </Link>
                </div>
            </div>
        </div>
    );
}
