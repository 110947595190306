import React from 'react';

export default function Textarea({ label, value, placeholder, error, onChange }) {
    return (
        <div className="w-full">
            <label className="block mb-2 text-sm text-secondary">
                {label}
            </label>
            <textarea
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                className={`w-full h-24 p-3 text-sm text-secondary bg-white border ${error?.message ? "border-error" : "border-borderColor"} rounded-md`}
                aria-label={label}
            />
            {error && (
                <p className="mt-1 text-xs text-error ml-1">{error.message}</p>
            )}
        </div>
    );
}
