// import React, { useState } from 'react';
import React from 'react';

import { useTranslation } from 'react-i18next';

export default function Checkbox({ label, required = true, onChange }) {
    const { t } = useTranslation();
    // const [isChecked, setIsChecked] = useState(false);

    // Properly handle the required prop
    const isRequired = typeof required === 'string' ? required.toLowerCase() !== 'false' : Boolean(required);

    // Handle checkbox change
    const handleCheckboxChange = (event) => {
        // setIsChecked(event.target.checked);
        if (onChange) {
            onChange(event.target.checked);
        }
    };

    return (
        <div className='flex items-center custom-checkbox-wrapper'>
            <input
                type="checkbox"
                id={label}
                className="custom-checkbox"
                required={isRequired}
                onChange={handleCheckboxChange}
            />
            <label htmlFor={label} className="text-sm ml-2 cursor-pointer">
                {t(`formInputs.${label}`)}
                {!isRequired && (
                    <span className="ml-1 text-xs">
                        {t("formInputs.optional")}
                    </span>
                )}
            </label>
        </div>
    );
}