import { useEffect } from 'react';

const useIntersectionObserver = (refs, animationClasses, delayFactor = 0, threshold = 0.75) => {
    const isTabletOrMobile = window.innerWidth <= 768;

    useEffect(() => {
        const observers = refs.map((ref, index) => {
            return new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            setTimeout(() => {
                                ref.current.classList.add(animationClasses[index]);
                            }, index * delayFactor);
                        }
                    });
                },
                { threshold: isTabletOrMobile ? threshold / 2 : threshold }
            );
        });

        refs.forEach((ref, index) => {
            if (ref.current) {
                observers[index].observe(ref.current);
            }
        });

        return () => {
            refs.forEach((ref, index) => {
                if (ref.current) {
                    observers[index].unobserve(ref.current);
                }
            });
        };
    }, [refs, animationClasses, delayFactor, threshold, isTabletOrMobile]);
};

export default useIntersectionObserver;
