import { useTranslation } from 'react-i18next';

import TitleQuotation from './../components/title-quotation';
import PaiementQuotation from './../components/paiement-quotation';

import Hr from '../../../components/Style/hr';

export default function ThirdStepAcceptQuotation({ quotation, returnedData }) {
    const { t } = useTranslation();

    const handleReturnedData = (data) => {
        returnedData(data);
    };

    return (
        <>
            <TitleQuotation title={t('pageQuotation.step3.welcomeMessage')} description={t('pageQuotation.step3.description')} step="3" />
            <Hr />
            <PaiementQuotation quotation={quotation} returnedData={handleReturnedData} />
        </>
    );
}