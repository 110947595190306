import React, { useEffect, useState } from 'react'

import FullWidthImage from '../Images/image-full-width';
import DescriptionSplit from '../Description/description-split';
import H3 from '../Titles/h3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

export default function ActivityCard({ activity }) {
    const [image, setImg] = useState(null);

    useEffect(() => {
        const loadImage = async () => {
            try {
                const imageModule = await import(`../../assets/images/activity/${activity.img}`);
                const image = imageModule.default;
                setImg(image);
            } catch (error) {
                console.error('Error loading image:', error);
            }
        };

        loadImage();
    }, [activity]);

    return (
        <div>
            <FullWidthImage url={image} />

            <div className="text-left pl-4">
                <div className="mt-6">
                    <H3 title={activity.title} />
                </div>
                <div className="my-5">
                    <DescriptionSplit description={activity.description} />
                </div>
                <div className="">
                    <FontAwesomeIcon icon={faLocationDot} className="text-main" />
                    <span className="text-secondary pl-2">{activity.distance}</span>
                </div>
            </div>
        </div>
    );
}